import { Environment, OrUndefined } from "config/types";

import { defaultsDeep, get, isEmpty, isFunction, result, template, toLower, trim } from "lodash-es";
import { local as localStore, session as sessionStore } from "store2";

import * as defaultConstants from "./defaults";
import { local, url } from "./validators";
import project from "../../../package.json";
/*

..................................................
|| This file contains all configurable constants
|| that this application relies on. Many of these
|| constants have defaults which can be accessed
|| in the `./defaults.ts` file. Constants which
|| are indexed with [REACT_APP] are inherited from
|| the root .env file. To set defaults, make sure
|| the indexed property matches an exported
|| constant in the defaults file listed above.
||
||
|| The level of importance on these constants are
|| ranked with [*] symbols; the quantity
|| representing its importance.
..................................................
*/

/* ----------------------------- METHODS -------------------------------- */
type ConstantOptions<T> = {
  fallback?: T;
  validator?(arg: string): OrUndefined<string>;
};

export const EnvOverrideKey = "environment_constants" as const;

const getEnvCollection = (defaultCollection: Record<string, unknown> = {}) => {
  if (defaultCollection.REACT_APP_ENV === "production") return defaultCollection;

  const localCollection = localStore.get(EnvOverrideKey) || {};
  const sessionCollection = sessionStore.get(EnvOverrideKey) || {};
  return defaultsDeep({}, sessionCollection, localCollection, defaultCollection);
};

const fb = (value: unknown, fallback: unknown) => (!isEmpty(value) ? value : fallback);

const defaults = getEnvCollection(defaultConstants);
const defaultConstant = (value: unknown, fallback: string) => fb(value, result(defaults, fallback));
const envCollection = getEnvCollection(process.env);
const validate = <T = string>(
  property: string,
  validator?: (arg: string) => OrUndefined<string>,
  fallback?: T,
  collection = envCollection
) =>
  isFunction(validator)
    ? isEmpty(validator(get(collection, property, "")))
      ? result(defaults, property, fallback)
      : validator(get(collection, property, ""))
    : get(collection, property, result(defaults, property, fallback));

const constant = <T = string>(property: string, options?: ConstantOptions<T>) =>
  validate<T>(property, options?.validator, options?.fallback) as NonNullable<T>;

const isTruthy = (value = ""): boolean => ["true", "yes", "1"].includes(trim(toLower(value)));
/* ----------------------------- END OF METHODS -------------------------------- */

// This is the current environment this app is running on.
export const env = constant<Environment>("REACT_APP_ENV");

// This is the current client name.
export const ClientName = constant("CLIENT_NAME");

// The current version of the project, which is taken from root package.json.
export const version = defaultConstant(get(project, "version"), "defaultVersion");

// The base URL which we use to interact with the backend.
export const PlatformApiUrl = constant("REACT_APP_PLATFORM_API_URL", { validator: url });

// The base URL which will be used as a fallback if the REACT_APP_PLATFORM_API_URL is unhealthy.
export const PlatformApiFallbackUrl = constant("REACT_APP_PLATFORM_API_FALLBACK_URL", {
  validator: url,
});

// The endpoint which will be used to check the health of the backend API.
export const PlatformApiHealthEndpoint = constant("REACT_APP_PLATFORM_API_HEALTH_ENDPOINT");

// The storage key name that we use to keep our token storage.
export const AuthenticationStorageKey = constant("REACT_APP_AUTH_STORAGE_KEY");

// OAuth redirect url which is used in token authorization.
export const OAuthRedirectUri = constant("REACT_APP_OAUTH_REDIRECT_URI");

// OAuth client ID which is used in token authorization.
export const OAuthClientId = constant("REACT_APP_OAUTH_CLIENT_ID");

export const OAuthSessionStorageKey = constant("defaultOAuthSessionStorageKey");

// Secret API Key for Socure Document Verification
export const SocureSDKKey = constant("TF_VAR_react_app_socure_sdk_key");

// Secret API Key for Stripe Payment Processing
export const StripeAPIKey = constant("REACT_APP_STRIPE_API_KEY");

// Secret API Key for Branch
export const BranchAPIKey = constant("REACT_APP_BRANCH_API_KEY");

// API key and endpoint for Braze
export const BrazeAPIKey = constant("BRAZE_API_KEY_WEB");
export const BrazeCustomEndpoint = constant("BRAZE_CUSTOM_ENDPOINT");
export const BrazeEnableLogging = constant("BRAZE_LOGGING_ENABLED");

// Allow token refresh to bypass login and 2fa
export const allowTokenRefresh = isTruthy(constant("REACT_APP_ALLOW_TOKEN_REFRESH"));

// Number of minutes till refreshing a new token.
export const tokenSilentRefreshMinutes = constant<number>("REACT_APP_TOKEN_SILENT_REFRESH_MINUTES");

// The client fingerprint used in request headers X-Client-Fingeprint
export const XClientFingerprint = constant("REACT_APP_X_CLIENT_FINGERPRINT");

// Client IDs needed for MediaAlpha integration
export const MediaAlphaIneligibleAgeId = constant("REACT_APP_MEDIA_ALPHA_INELIGIBLE_AGE_ID");
export const MediaAlphaIneligibleStateId = constant("REACT_APP_MEDIA_ALPHA_INELIGIBLE_STATE_ID");

// This is the current blog URL.
// export const BlogUrl = constant("BLOG_URL", );
export const BlogUrl = constant("BLOG_URL", { fallback: "https://blog.wysh.com" });

// This is the current FAQ URL.
export const FaqUrl = constant("FAQ_URL", { fallback: "https://support.wysh.com/" });

// This is the current FAQ URL.
export const FDICNationalRatesUrl = constant("FDIC_URL", {
  fallback: "https://www.fdic.gov/resources/bankers/national-rates/index.html",
});

// This is the current Underwriting FAQ URL.
export const UWFaqUrl = constant("UW_FAQ_URL", {
  fallback: "https://support.wysh.com/hc/en-us/articles/6693270118289-What-is-manual-underwriting-",
});

// This is the current AD Disclosure URL.
export const ADDisclosuresURL = constant("AD_DISCLOSURES_URL", {
  fallback: "https://www.wysh.com/accidental-death-ad-disclosures",
});

// This is the current Manual Underwriting video URL
export const ManualUnderwritingURL = constant("MANUAL_UNDERWRITING_URL", {
  fallback: "https://player.vimeo.com/video/844749454",
});

// This is the current Savings+ Waitlist URL.
export const SavingsWaitlistURL = constant("WYSHBOX_SAVINGS_WAITLIST_URL", {
  fallback: "https://www.wysh.com/savings-waitlist",
});

// This is the current Life Insurance Payout FAQ URL.
export const WyshLifeInsurancePayoutURL = constant("LIFE_INSURANCE_PAYOUT_URL", {
  fallback:
    "https://support.wysh.com/hc/en-us/articles/18141012933905-What-is-Life-Insurance-Payout-",
});

// This is the current Wyshbox URL.
export const WyshboxURL = constant("WYSHBOX_URL", { fallback: "https://www.wysh.com" });

// This is the current Facebook URL.
export const FacebookURL = constant("FACEBOOK_URL", {
  fallback: " https://www.facebook.com/wearewysh ",
});

// This is the current Instagram URL.
export const InstagramURL = constant("INSTAGRAM_URL", {
  fallback: "https://www.instagram.com/we_are_wysh/",
});

// This is the current LinkedIn URL.
export const LinkedInURL = constant("LINKEDIN_URL", {
  fallback: "https://www.linkedin.com/company/wearewysh/",
});

// This is the current Twitter URL.
export const TwitterURL = constant("TWITTER_URL", {
  fallback: "https://twitter.com/We_Are_Wysh",
});

// Key for Zendesk
export const ZendeskKey = constant("REACT_APP_ZENDESK_KEY");

// Key for Google Tag Manager
export const GtmKey = constant("REACT_APP_GTM_KEY");

// Smarty Address API Key
export const SmartyAddressKey = constant("SMARTY_EMBEDDED_KEY");

// ElasticAPM Server URL
export const ElasticApmServerUrl = constant("REACT_APP_ELASTIC_APM_SERVER_URL");

// OpenSSL Public Encryption Key
export const EncryptionKeys = {
  default: constant("OPENSSL_ENCRYPTION_KEY"),
  local: constant("OPENSSL_ENCRYPTION_KEY_LOCAL"),
  staging: constant("OPENSSL_ENCRYPTION_KEY_STAGING"),
} as const;

export const EncryptionKey = EncryptionKeys.default;

// URL endpoint for authorizing OAuth.
export const OAuthAuthorizeRoute = "oauth/authorize";

// URL endpoint for validating token.
export const OAuthTokenRoute = "oauth/token";

// Default fallback message for error responses.
export const defaultErrorMessage = "Something went wrong.";

// Default Debug mode
export const debugMode = constant("REACT_APP_DEBUG_MODE");

// Admin auth token for fetching user notifications locally.
export const AdminAuthToken = constant("REACT_APP_ADMIN_AUTH_TOKEN", { validator: local });

// eslint-disable-next-line no-template-curly-in-string
export const pageTitle = template("${baseTitle} ${baseTitleSeparator} ${title}");

// Default password length
export const defaultPasswordLength = 12;

// Allow for the Journey Progress to sync locally
export const SyncJourneyLocally = isTruthy(constant("REACT_APP_JOURNEY_LOCAL_SYNC"));

// Latest version of form.slug for Onboarding Estimate
export const EstimateFormSlug = "estimate-form";

// Method to get dev tools config.
export const getDevToolsConfig = () => {
  const defaultConfig = true;
  const config = get(defaults.defaultDevToolsConfig, env, defaultConfig);
  return config;
};

// Method to get current page title.
export const getPageTitle = (): string => {
  const defaultOptions = defaults.defaultPageTitleOptions;
  const storeOptions = {};
  const options = defaultsDeep(storeOptions, defaultOptions);
  return pageTitle(options);
};

// This object extends the default state of all entities.
export const entityDefaultState = {
  status: defaults.defaultEntityStatus,
};

export * from "./defaults";
