import { gql } from "config/graphql";

import { FEATURE_FLAG_FIELDS } from "./featureFlag";
import { INSURANCE_MEMBERSHIP_FIELDS } from "./insuranceMembership";
import { WYSH_ALLOCATION_FIELDS } from "./wysh";

export const CONSTELLATION_FIELDS = gql`
  ${FEATURE_FLAG_FIELDS}
  ${INSURANCE_MEMBERSHIP_FIELDS}
  ${WYSH_ALLOCATION_FIELDS}
  fragment ConstellationFields on Constellation {
    activeInsuranceMembership {
      ...InsuranceMembershipFields
    }
    allocationSummary {
      activeCount
      totalAmount
      percentCovered
    }
    createdAt
    id
    label
    featureFlags {
      ...FeatureFlagFields
    }
    plaidItems {
      id
    }
    status
    wyshAllocations {
      ...WyshAllocationFields
    }
  }
`;
