import { FC, useEffect, useState, useCallback } from "react";

import Button from "components/Button/Button";
import { IDocument } from "config";
import { useModal, useTranslation } from "modules";
import { useAppDispatch, useAppSelector } from "store";
import { getPolicyDocuments } from "store/actions/policy";

import { findLast } from "lodash-es";

export const DownloadPolicyPacketButton: FC = () => {
  const openModal = useModal();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const policyDocuments = useAppSelector(state => state.policy.documents);
  const [policyDocumentLink, setPolicyDocumentLink] = useState("");
  const [fetchingPolicyDocument, setFetchingPolicyDocument] = useState(false);

  const parseDocumentUrl = useCallback((documents: IDocument[]) => {
    const policyAgreement = findLast(documents, doc => doc.slug === "policy-agreement");
    return policyAgreement?.html?.url || policyAgreement?.pdf?.url || "";
  }, []);

  useEffect(() => {
    if (!policyDocumentLink) {
      setPolicyDocumentLink(parseDocumentUrl(policyDocuments));
    }
  }, [policyDocuments, policyDocumentLink, parseDocumentUrl]);

  const fetchLatestPolicyDocument = useCallback(async () => {
    setFetchingPolicyDocument(true);
    try {
      const documents = await dispatch(getPolicyDocuments()).unwrap();
      return parseDocumentUrl(documents);
    } catch (error) {
      return undefined;
    } finally {
      setFetchingPolicyDocument(false);
    }
  }, [dispatch, parseDocumentUrl]);

  const handleClick = async () => {
    const newWindow = window.open("about:blank", "_blank");
    try {
      const link = policyDocumentLink || (await fetchLatestPolicyDocument());
      if (link && newWindow) {
        setPolicyDocumentLink(link);
        newWindow.location.href = link;
      } else {
        throw new Error();
      }
    } catch {
      newWindow?.close();
      openModal("DownloadPolicyPacketErrorModal");
    }
  };

  return (
    <Button
      className="payment-confirmation-details__download_cta__button"
      dataTestId="payment-confirmation__download_cta"
      subtype="text-dark"
      icon="Download"
      align="left"
      tag="wl"
      text={t("payment_confirmation.details.download_policy_packet.cta", "Download policy details")}
      isLoading={fetchingPolicyDocument}
      onClick={handleClick}
    />
  );
};
