import { useSelector } from "react-redux";

import { IDashboardCardAccountData } from "config/types";
import { RootState } from "store";
import { getHasRUWCase, getWyshADOffering, getWyshTermLifeOffering } from "store/selectors";

export const useAccountData = () => {
  const activePolicy = useSelector((state: RootState) => state.policy);
  const adOffer = useSelector(getWyshADOffering);
  const allocated = useSelector((state: RootState) => state.wyshAllocations.allocated);
  const allocationSummary = useSelector((state: RootState) => state.allocationSummary);
  const isUWOnCooldown = useSelector((state: RootState) => !state.account.eligibleForUnderwriting);
  const pendingQuote = useSelector((state: RootState) => state.quote);
  const user = useSelector((state: RootState) => state.user);
  const userHasEstimate = useSelector((state: RootState) => !!state.estimate.id);
  const userHasRUWCase = useSelector(getHasRUWCase);
  const termOffer = useSelector(getWyshTermLifeOffering);

  const accountData: Omit<IDashboardCardAccountData, "fetched"> = {
    isUWOnCooldown,
    pendingQuote,
    user,
    userHasEstimate,
    userHasRUWCase,
    adOffer: adOffer,
    isADOnly: !!adOffer && !termOffer,
    policy: activePolicy,
    termOffer: termOffer,
    userHasADOffer: !!adOffer,
    userHasPolicy: !!activePolicy?.id,
    userHasWishes: !!allocated.length,
    wishes: allocated,
    wishesCovered: allocationSummary.percentCovered,
    wyshesTotal: allocationSummary.totalAmount,
  };

  return accountData;
};
