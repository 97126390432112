import { ElasticApmServerUrl, env, PlatformApiFallbackUrl, PlatformApiUrl, version } from "config";

import { ApmBase, init } from "@elastic/apm-rum";

interface IApmTransaction {
  spans: {
    context?: {
      custom?: {
        path?: string;
      };
    };
  }[];
}

let apm: ApmBase;

function getApm() {
  if (!apm) {
    throw new Error("Elastic APM has not been initialized yet");
  }

  return apm;
}

function initApm() {
  if (!apm) {
    apm = init({
      active: ["production", "staging"].includes(env),
      distributedTracingOrigins: [PlatformApiUrl, PlatformApiFallbackUrl],
      environment: env,
      propagateTracestate: true,
      serverUrl: ElasticApmServerUrl,
      serviceVersion: version as string,
      serviceName: "Wyshware Web",
    });
  }

  apm.addFilter(function(payload) {
    if (payload.transactions) {
      payload.transactions.forEach(function(transaction: IApmTransaction) {
        transaction.spans.forEach(function(span) {
          if (span.context?.custom?.path) {
            span.context.custom.path = undefined;
          }
        });
      });
    }

    return payload;
  });

  return apm;
}

export { getApm as get, initApm as init };
