import { gql } from "config/graphql";
import { IFinanceCustomer, IProfile } from "config/types";
import { getApolloClient } from "store/apollo";

interface IFetchProfileResponse {
  data: {
    me: IProfile;
  };
}

export const fetchProfile = (): Promise<IFetchProfileResponse> => {
  const client = getApolloClient();

  const query = gql`
    query currentUserProfile {
      me {
        birthdate
        firstName
        gender
        lastName
        middleName
        suffix
        emails {
          id
          email
          status
        }
        phoneNumbers {
          id
          createdAt
          phoneNumber
          status
          mask
        }
        activeAddress {
          formattedDisplay
          street1
          street2
          city
          state
          postalCode
          country {
            id
            name
            alpha3
            hasStates
            hasPostalCode
            postalCodeFormat
          }
          stateObject {
            id
            name
            alpha2
          }
        }
      }
    }
  `;

  return client.query({ query });
};

interface IProfileReviewPersonalInfoResponse {
  data: {
    me: Omit<IProfile, "emails"> & { financeCustomer: Pick<IFinanceCustomer, "status"> };
  };
}

export const fetchProfileReviewPersonalInfo = (): Promise<IProfileReviewPersonalInfoResponse> => {
  const client = getApolloClient();

  const query = gql`
    query ProfileReviewPersonalInfo {
      me {
        activeAddress {
          city
          formattedDisplay
          postalCode
          state
          street1
          street2
        }
        birthdate
        firstName
        gender
        lastName
        middleName
        phoneNumbers {
          createdAt
          id
          phoneNumber
          status
        }
        suffix
        taxIdSet
      }
    }
  `;

  return client.query({ query });
};
