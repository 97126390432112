import { apolloClient } from "config/apollo";
import { gql } from "config/graphql";
import {
  DynamicFormCriteriaFlowScope,
  IApiArgument,
  IDynamicForm,
  IFlow,
  IFlowForm,
  IFlowSession,
} from "config/types";
import { DYNAMIC_FORM } from "graphql/fragments";
import { retryQuery } from "utils/retryQuery";

export type IFlowResponse = { flow: Pick<IFlow, "id" | "terminalBehavior" | "slug"> };

export const flowQuery = (slug: IFlow["slug"]) => {
  const query = gql`
    query Flow($slug: String!) {
      flow(slug: $slug) {
        id
        terminalBehavior
        slug
      }
    }
  `;

  return apolloClient.query<IFlowResponse>({
    variables: { slug },
    query,
  });
};

interface INextFormResponse {
  nextForm: IFlowForm;
}

export const nextFormOldQuery = (flowSessionId: IFlowSession["id"]) => {
  const query = gql`
    query NextFormOld($flowSessionId: ID!) {
      nextForm: nextFormOld(flowSessionId: $flowSessionId) {
        id
        title
        prompt
        layout
        terminal
        section {
          id
        }
        questions {
          id
          prompt
          layout
          description
          justification
          justificationHeader
          fields {
            id
            label
            inputType
            metadata
            required
            key
          }
        }
      }
    }
  `;

  return apolloClient.query<INextFormResponse>({
    variables: { flowSessionId },
    query,
  });
};

type DynamicFormResponse = {
  nextForm: IDynamicForm;
};

export const nextFormQuery = (
  flowSessionId: IFlowSession["id"],
  dynamicFormId?: IDynamicForm["id"]
) => {
  const query = gql`
    ${DYNAMIC_FORM}
    query NextForm($flowSessionId: ID!, $currentFormId: ID) {
      nextForm(flowSessionId: $flowSessionId, currentFormId: $currentFormId) {
        ...DynamicFormFields
      }
    }
  `;

  return apolloClient.query<DynamicFormResponse>({
    variables: {
      flowSessionId,
      currentFormId: dynamicFormId,
    },
    query,
  });
};

export interface IDynamicFormQueryInputs {
  flowSessionId: IFlowSession["id"];
  criteria?: IInputsDynamicFormCriteria;
  currentFormIdentifier?: IDynamicForm["id"];
  direction?: "next" | "previous";
}

export interface INextFormInput extends IDynamicFormQueryInputs {
  retryArguments?: IApiArgument[];
}

interface IInputsDynamicFormCriteria {
  action?: TDynamicFormCriteriaAction;
  flowScope?: DynamicFormCriteriaFlowScope;
  formScope?: string;
}

type TDynamicFormCriteriaAction =
  | "add_backup"
  | "add_primary"
  | "cancel"
  | "delete"
  | "dont_promote"
  | "edit_allocation"
  | "edit_beneficiary"
  | "individual"
  | "minor"
  | "organization"
  | "promote"
  | "save"
  | "trust"
  | string;

type DynamicFormQueryResponse = {
  dynamicForm: IDynamicForm;
};

export const dynamicFormQuery = async (inputPayload: INextFormInput) => {
  const query = gql`
    ${DYNAMIC_FORM}
    query DynamicForm(
      $criteria: InputsDynamicFormsFormsCriteriaAttributes
      $currentFormIdentifier: String
      $direction: DynamicFormsFormsDirection
      $flowSessionId: ID!
    ) {
      dynamicForm(
        criteria: $criteria
        currentFormIdentifier: $currentFormIdentifier
        direction: $direction
        flowSessionId: $flowSessionId
      ) {
        ...DynamicFormFields
      }
    }
  `;

  const watchQuery = apolloClient.watchQuery<DynamicFormQueryResponse>({
    variables: {
      ...inputPayload,
    },
    query,
  });

  if (inputPayload.retryArguments?.length) {
    watchQuery.setOptions({ errorPolicy: "all" });
    const retryQueryResult = await retryQuery(watchQuery, inputPayload.retryArguments);

    return retryQueryResult;
  }

  return watchQuery.result();
};
