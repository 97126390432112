import { MouseEvent } from "react";

import { ClassName, IListRowColumnContent } from "config/types";
import {
  IDynamicFormItemProps,
  ImageItem,
  SpacerItem,
  TextItem,
} from "containers/DynamicForm/DynamicFormItems";
import { useButtonAction } from "containers/DynamicForm/useButtonAction";

import classNames from "classnames";
import { kebabCase } from "lodash-es";

interface IProps {
  column: IListRowColumnContent;
  itemKey: string;
  type: "leading" | "trailing";
  className?: ClassName;
  verticalPadding?: IDynamicFormItemProps<"listRow">["item"]["content"]["verticalPadding"];
}

export function ListRowItemColumn(props: IProps) {
  const { className, itemKey, type, verticalPadding } = props;
  const column = props.column || {};
  const actions = column.actions || [];
  const image = column.image;
  const texts = column.texts || [];

  const actionCommands = useButtonAction(actions);
  const classes = classNames(
    "form-item-list-row__column",
    `form-item-list-row__column--${type}`,
    {
      "form-item-list-row__column--clickable": actions.length,
      "form-item-list-row__column--with-image": !!image,
    },
    className
  );

  const handleOnClick = async (event: MouseEvent) => {
    if (actions.length) {
      event.stopPropagation();

      await actionCommands();
    }
  };

  const imageItemElement = image && (
    <ImageItem item={{ content: image, key: `${itemKey}-${type}-image`, type: "image" }} />
  );
  const spacerItemElement = verticalPadding && (
    <SpacerItem
      item={{
        content: { min: verticalPadding, mode: "fixed" },
        key: `${itemKey}-${type}-spacer`,
        type: "space",
      }}
    />
  );

  return (
    <div className={classes} onClick={handleOnClick} data-testid={itemKey}>
      {spacerItemElement}
      {type === "leading" && imageItemElement}
      {texts.length > 0 && (
        <div className="form-item-list-row-column__texts">
          {texts.map((content, index) => {
            const textItemKey = `${itemKey}-text-${content.text ? kebabCase(content.text) : index}`;

            return (
              <TextItem key={textItemKey} item={{ content, key: textItemKey, type: "text" }} />
            );
          })}
        </div>
      )}

      {type === "trailing" && imageItemElement}
      {spacerItemElement}
    </div>
  );
}
