import { TBeneficiaryAssignedEntity, ContactType, IBeneficiary } from "config";
import { logout } from "store/actions/authentication";
import {
  createBeneficiary,
  destroyBeneficiary,
  getBeneficiaries,
  updateBeneficiary,
} from "store/actions/beneficiaries";

import { createReducer } from "@reduxjs/toolkit";
import { isEmpty, partition } from "lodash-es";

type ReducerStatus = {
  isLoaded: boolean;
  isLoading: boolean;
  isEmpty: boolean;
};

type BeneficiaryReducer = {
  backupIds: Record<TBeneficiaryAssignedEntity, IBeneficiary["id"][]>;
  beneficiaries: Record<IBeneficiary["id"], IBeneficiary>;
  primaryIds: Record<TBeneficiaryAssignedEntity, IBeneficiary["id"][]>;
  status: ReducerStatus;
};

const defaultState: BeneficiaryReducer = {
  backupIds: {
    InsuranceMembership: [],
    FinanceCustomer: [],
    Policy: [],
  },
  beneficiaries: {},
  primaryIds: {
    InsuranceMembership: [],
    FinanceCustomer: [],
    Policy: [],
  },
  status: {
    isEmpty: true,
    isLoaded: false,
    isLoading: false,
  },
};

const buildReducerStatus = (state: BeneficiaryReducer, loading: boolean): ReducerStatus => {
  const empty = isEmpty(Object.keys(beneficiaries));
  const isLoading = loading;
  const isLoaded = !isLoading && !empty;
  return { isLoaded, isLoading, isEmpty: empty };
};

function formatBeneficiary(beneficiary: IBeneficiary) {
  return {
    ...beneficiary,
    contact: {
      ...beneficiary.contact,
      type: beneficiary.contact.__typename.toLowerCase() as ContactType,
    },
  };
}

const beneficiaries = createReducer(defaultState, builder => {
  builder
    .addCase(getBeneficiaries.pending, state => {
      state.status = buildReducerStatus(state, true);
    })
    .addCase(getBeneficiaries.fulfilled, (state, action) => {
      const [insurancePrimaries, insuranceBackups] = partition(
        action.payload.insuranceBeneficiaries,
        { responsibility: "primary" }
      );
      const [insuranceApplicationPrimaries, insuranceApplicationBackups] = partition(
        action.payload.insuranceApplicationBeneficiaries,
        { responsibility: "primary" }
      );

      state.primaryIds["InsuranceMembership"] = insuranceApplicationPrimaries.map(({ id }) => id);
      state.backupIds["InsuranceMembership"] = insuranceApplicationBackups.map(({ id }) => id);
      state.primaryIds["Policy"] = insurancePrimaries.map(({ id }) => id);
      state.backupIds["Policy"] = insuranceBackups.map(({ id }) => id);

      state.beneficiaries = [
        ...insurancePrimaries,
        ...insuranceBackups,
        ...insuranceApplicationPrimaries,
        ...insuranceApplicationBackups,
      ].reduce((memo: BeneficiaryReducer["beneficiaries"], beneficiary: IBeneficiary) => {
        memo[beneficiary.id] = formatBeneficiary(beneficiary);

        return memo;
      }, {});

      state.status = buildReducerStatus(state, false);
    })
    .addCase(createBeneficiary.fulfilled, (state, action) => {
      const beneficiary = action.payload;
      const beneficiaryAssignedEntity = beneficiary.assignedEntity.entityType;

      const beneficiaryResponsibility = beneficiary.responsibility;

      state.beneficiaries[beneficiary.id] = formatBeneficiary(beneficiary);

      if (beneficiaryResponsibility === "primary") {
        state.primaryIds[beneficiaryAssignedEntity].push(beneficiary.id);
      } else if (beneficiaryResponsibility === "backup") {
        state.backupIds[beneficiaryAssignedEntity].push(beneficiary.id);
      }
    })
    .addCase(updateBeneficiary.fulfilled, (state, action) => {
      const beneficiary = action.payload;
      const beneficiaryAssignedEntity = beneficiary.assignedEntity.entityType;
      const previousBeneficiaryId = action.meta.arg.beneficiaryId;

      delete state.beneficiaries[previousBeneficiaryId];
      state.beneficiaries[beneficiary.id] = formatBeneficiary(beneficiary);

      if (beneficiary.responsibility === "primary") {
        state.primaryIds[beneficiaryAssignedEntity] = state.primaryIds[
          beneficiaryAssignedEntity
        ].map(id => {
          if (id === previousBeneficiaryId) {
            return beneficiary.id;
          }

          return id;
        });
      } else if (beneficiary.responsibility === "backup") {
        state.backupIds[beneficiaryAssignedEntity] = state.backupIds[beneficiaryAssignedEntity].map(
          id => {
            if (id === previousBeneficiaryId) {
              return beneficiary.id;
            }

            return id;
          }
        );
      }
    })
    .addCase(destroyBeneficiary.fulfilled, (state, action) => {
      const { beneficiaryId, responsibility } = action.payload;

      const beneficiary = state.beneficiaries[beneficiaryId];
      const beneficiaryAssignedEntity = beneficiary.assignedEntity.entityType;

      delete state.beneficiaries[beneficiaryId];

      if (responsibility === "primary") {
        state.primaryIds[beneficiaryAssignedEntity] = state.primaryIds[
          beneficiaryAssignedEntity
        ].filter(id => id !== beneficiaryId);
      } else if (responsibility === "backup") {
        state.backupIds[beneficiaryAssignedEntity] = state.backupIds[
          beneficiaryAssignedEntity
        ].filter(id => id !== beneficiaryId);
      }
    })
    .addCase(logout, () => defaultState);
});

export default beneficiaries;
