import { ButtonFormItem } from "config";
import { useAppSelector } from "store/index";
import { isValidPolicy } from "utils/policy";

type TLeadingNavigationButtonContent = ButtonFormItem["content"];

// WIP: special use case for IDynamicForm.leadingNavigationButton on "purchase-policy" form
export const usePurchasePolicyLeadingNavigation = (
  leadingNavigationButton?: TLeadingNavigationButtonContent
) => {
  // HOOKS
  const doesNotHavePolicyOrPayment = useAppSelector(
    state => !(isValidPolicy(state.policy) || state.payment.status === "active")
  );

  // Replace default ButtonItem 'pop' action with exit flow
  const exitFlowActions: TLeadingNavigationButtonContent["actions"] = [
    { type: "api", command: { destination: "exitFlowWarning" } },
    {
      type: "api",
      command: {
        destination: "finishFlow",
        arguments: [
          {
            name: "link",
            source: "wyshware.app://screen/home",
            sourceType: "static",
            type: "string",
          },
        ],
      },
    },
  ];

  if (!leadingNavigationButton) {
    return;
  }

  const purchasePolicyLeadingNavigationButton: TLeadingNavigationButtonContent = {
    actions: doesNotHavePolicyOrPayment ? leadingNavigationButton.actions : exitFlowActions,
    fixed: leadingNavigationButton.fixed,
    primary: leadingNavigationButton.primary,
    style: doesNotHavePolicyOrPayment ? "back" : "exit",
    text: leadingNavigationButton.text,
  };

  return purchasePolicyLeadingNavigationButton;
};
