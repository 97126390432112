import React, { FC } from "react";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";

const DownloadPolicyPacketErrorModal: FC<IModalProps> = ({ closeModal }) => {
  const { t } = useTranslation();

  const content = {
    header: t(
      "payment_confirmation.download_policy_packet_error_modal.header",
      "Policy details unavailable"
    ),
    subheader: t(
      "payment_confirmation.download_policy_packet_error_modal.subheader",
      "Your policy document file is not ready just yet. Try again in a few minutes. If the problem continues, please contact support."
    ),
    button: [
      {
        text: t("savings.autodeposit.setup.modal.date_error.cta.primary", "OKAY"),
        subType: "primary",
        onClick: () => closeModal(),
      },
    ],
  };

  return (
    <Layout as="ModalLayout" className="download_policy_packet_error_modal" content={content} />
  );
};

export default DownloadPolicyPacketErrorModal;
