import { gql } from "config/graphql";
import { IUser } from "config/types";
import { INSURANCE_APPLICATION_FIELDS, PRODUCT_DETAIL_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

interface IFetchDashboardDataResponse {
  data: {
    me: IUser;
  };
}

export const fetchDashboardHomeQuery = (): Promise<IFetchDashboardDataResponse> => {
  const client = getApolloClient();

  const query = gql`
    ${INSURANCE_APPLICATION_FIELDS}
    ${PRODUCT_DETAIL_FIELDS}
    query DashboardHome {
      me {
        firstName
        activeConstellations {
          activeInsuranceMembership {
            label
            pendingPolicy {
              id
            }
            pendingQuote {
              id
              expiresAt
              product {
                ...ProductFields
              }
              status
            }
            activePolicy {
              amount
              product {
                activePlan {
                  nextPayment {
                    date
                  }
                }
              }
              productV2 {
                slug
              }
            }
            latestEstimateDatum {
              id
            }
            latestInsuranceApplication {
              ...InsuranceApplicationFields
            }
            productsOfferings {
              id
            }
          }
          allocationSummary {
            activeCount
            totalAmount
            percentCovered
          }
          wyshAllocations {
            id
            name
            percentCovered
            amount
            wysh {
              icon100100 {
                formats {
                  url
                  contentType
                }
              }
              wyshCategory {
                id
                name
                color
              }
            }
          }
        }
      }
    }
  `;

  return client.query({
    fetchPolicy: "no-cache",
    query,
  });
};
