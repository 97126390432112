import { ICoverageDetails, ProductDetailSlug } from "config";
import { getPreVerifiedInitialData } from "store/actions/appLoad";
import { getCurrentUserEstimate } from "store/actions/estimate";
import { getActiveAccount } from "utils/account";

import { createReducer } from "@reduxjs/toolkit";
import { omit } from "lodash-es";

export type TCoverageDetailsState = Record<ProductDetailSlug, Omit<ICoverageDetails, "product">>;

const DEFAULT_STATE = <TCoverageDetailsState>{};

const coverageDetails = createReducer(DEFAULT_STATE, builder => {
  builder
    .addCase(getCurrentUserEstimate.fulfilled, (state, action) => {
      const { coverageDetails = [] } = action.payload;

      coverageDetails.reduce((memo, detail) => {
        memo[detail.product.slug] = omit(detail, ["product"]);

        return memo;
      }, state);
    })
    .addCase(getPreVerifiedInitialData.fulfilled, (state, action) => {
      const activeAccount = getActiveAccount(action.payload.me);

      const { coverageDetails = [] } = activeAccount;

      coverageDetails.reduce((memo, detail) => {
        memo[detail.product.slug] = omit(detail, ["product"]);

        return memo;
      }, state);
    });
});

export default coverageDetails;
