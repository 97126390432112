import "./ProductOfferingValueProps.scss";

import { MouseEvent } from "react";

import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ClassName } from "config";
import { useTranslation } from "modules";

import classNames from "classnames";

interface IProductOfferingValueProps {
  className?: ClassName;
  isReinstateablePolicy?: boolean;
  onChatClick: NonNullable<IValuePropsItem["cta"]>["onClick"];
}

interface IValuePropsItem {
  description: string;
  header: string;
  iconSrc: string;
  className?: ClassName;
  cta?: {
    text: string;
    onClick(event: MouseEvent<HTMLParagraphElement | HTMLHeadingElement>): void;
  };
}

export function ProductOfferingValueProps(props: IProductOfferingValueProps) {
  const { className, isReinstateablePolicy, onChatClick } = props;

  const { t } = useTranslation();

  return (
    <div className={classNames("product-offering-value-props", className)}>
      {!isReinstateablePolicy && (
        <ValuePropsItem
          className="product-offering-value-props__item"
          iconSrc="BlobMoneyGift"
          header={t("review_quotev2.term.props.item1.title", "NO HASSLE CANCELLATIONS")}
          description={t(
            "review_quotev2.term.props.item1.description",
            "Cancel your policy within the first 10 days for a full refund."
          )}
        />
      )}
      {!isReinstateablePolicy && (
        <ValuePropsItem
          className="product-offering-value-props__item"
          iconSrc="BlobPassword"
          header={t("review_quotev2.term.props.item2.title", "LOCK IN YOUR RATE TODAY")}
          description={t(
            "review_quotev2.term.props.item2.description",
            "You’re already approved! Just don’t let your quote expire:)"
          )}
        />
      )}
      <ValuePropsItem
        className="product-offering-value-props__item"
        iconSrc="BlobChatAgent"
        header={t("review_quotev2.term.props.item3.title", "NEED SOME HELP?")}
        description={t(
          "review_quotev2.term.props.item3.description",
          "Live support is available Monday-Friday during business hours (9am-5pm EST)."
        )}
        cta={{ onClick: onChatClick, text: t("review_quotev2.term.props.item3.cta", "Live Chat") }}
      />
    </div>
  );
}

function ValuePropsItem(props: IValuePropsItem) {
  const { className, cta, description, header, iconSrc } = props;

  return (
    <div className={classNames("product-offering-value-props-item", className)}>
      <Icon className="product-offering-value-props-item__icon" size="sm" src={iconSrc} />
      <Text className="product-offering-value-props-item__header" tag="c4-caption">
        {header}
      </Text>
      <Text className="product-offering-value-props-item__description" tag="p5-paragraph">
        {description}
      </Text>
      {!!cta && (
        <Text
          className="product-offering-value-props-item__cta"
          onClick={cta.onClick}
          tag="l5-label">
          {cta.text}
        </Text>
      )}
    </div>
  );
}
