import "./QuoteReviewLayout.scss";

import { useEffect, useState } from "react";

import { IDynamicForm } from "config";
import AboutAD from "containers/AboutAD/AboutAd";
import CaseResultAD from "containers/CaseResultAD/CaseResultAD";
import CaseResultTerm from "containers/CaseResultTerm/CaseResultTerm";
import CoverageEditorRoute from "router/CoverageEditorRoute/CoverageEditorRoute";
import { useAppDispatch } from "store";
import { setJourneyProgressPercentOverride } from "store/actions/journey";
import { getProductOfferings } from "store/actions/product-offerings";
import { getQuote } from "store/actions/quote";

interface IProps {
  items: IDynamicForm["items"];
  slug: IDynamicForm["slug"];
}

export function QuoteReviewLayout({ items, slug }: IProps) {
  const dispatch = useAppDispatch();
  const [editingType, setEditingType] = useState<"termLifeOffering" | "adOffering" | "">("");

  const onClickEdit = (offeringType: "termLifeOffering" | "adOffering") => {
    setEditingType(offeringType);
  };

  const resetEditState = () => {
    setEditingType("");
  };

  useEffect(() => {
    dispatch(setJourneyProgressPercentOverride(undefined));
    dispatch(getQuote());
    dispatch(getProductOfferings());
  }, [dispatch]);

  if (editingType) {
    return (
      <CoverageEditorRoute type={editingType} onSave={resetEditState} onBack={resetEditState} />
    );
  }

  if (slug === "about-accidental-death") {
    return <CaseResultAD dynamicFormItems={items} onClickEdit={onClickEdit} />;
  }

  if (slug === "quote-review-accidental-death") {
    return <AboutAD dynamicFormItems={items} onClickEdit={onClickEdit} />;
  }

  // Term life offering
  return <CaseResultTerm dynamicFormItems={items} onClickEdit={onClickEdit} />;
}
