import { useContext } from "react";

import { TApiButtonCommandFn } from ".";

import { welcomeFormSubmittedEvent } from "analytics/onboardingEvents";
import { EstimateFormSlug, FlowTypes } from "config";
import { fetchIneligibleForCoverageReasons } from "graphql/queries/account";
import { useJourney } from "modules";
import { useFlowRouteRedirect } from "router/FlowRoute/useFlowRouteRedirect";
import { getPreVerifiedInitialData } from "store/actions/appLoad";
import { getCurrentUserEstimate } from "store/actions/estimate";
import { finishFlow } from "store/actions/flow";
import { useAppSelector, useAppDispatch } from "store/index";
import replaceWyshwareUri from "utils/replaceWyshwareUri";

import { push } from "connected-react-router";
import { find, get } from "lodash-es";

import { DynamicFormContext } from "../DynamicFormContext";

export const useFinishFlow = () => {
  const dispatch = useAppDispatch();
  const flowTracker = useAppSelector(state => state.analyticsTracker);
  const flowSlug = flowTracker.slug as FlowTypes;
  const finishFlowRedirect = useFinishFlowRedirect(flowSlug);
  const { exitJourney } = useJourney();

  const finishFlowAnalytics = () => dispatch(finishFlow({ flowTracker }));

  const finishFlowCommand: TApiButtonCommandFn = async commandArgs => {
    const linkArgument = (commandArgs || []).find(argument => argument.name === "link");

    finishFlowAnalytics();

    if (linkArgument) {
      // Exit any active journeys before redirect to skip JourneyExit modal render
      await exitJourney();
      const linkPath = replaceWyshwareUri(linkArgument.source);
      dispatch(push(linkPath));
    } else {
      await finishFlowRedirect();
    }
  };

  return finishFlowCommand;
};

const useFinishFlowRedirect = (flowSlug: FlowTypes) => {
  const { nextStepCallback } = useJourney();
  const { redirectToNextPage } = useFlowRouteRedirect(flowSlug);
  const finishFlowAction = useFinishFlowAction(flowSlug);
  const finishFlowCallback = finishFlowAction && finishFlowAction();

  const finishFlowRedirect = async () => {
    if (finishFlowCallback) {
      await finishFlowCallback();
    } else {
      await nextStepCallback(redirectToNextPage, false)();
    }
  };

  return finishFlowRedirect;
};

const useFinishFlowAction = (flowSlug: FlowTypes) => {
  const actions: Partial<Record<FlowTypes, () => () => PromiseLike<void>>> = {
    [EstimateFormSlug]: useFinishEstimateFlow,
    "pre-verified-initial-data": useFinishPreVerifiedInitialDataFlow,
  };

  return actions[flowSlug];
};

const useFinishPreVerifiedInitialDataFlow = () => {
  const dispatch = useAppDispatch();

  const finishPreVerifiedInitialDataFlow = async () => {
    await dispatch(getPreVerifiedInitialData());
  };

  return finishPreVerifiedInitialDataFlow;
};

const useFinishEstimateFlow = () => {
  const dispatch = useAppDispatch();
  const { inputs } = useContext(DynamicFormContext);

  const finishEstimateFlow = async () => {
    dispatch(welcomeFormSubmittedEvent());

    const coverageInput = find(inputs, { key: "desired-coverage-amount" });

    const response = await fetchIneligibleForCoverageReasons();
    const reasons = get(response, "data.userIneligibleReasons.ineligibleCoverageReasons", []);

    const estimate = await dispatch(getCurrentUserEstimate()).unwrap();

    if (reasons.length) {
      const reason = reasons[0];

      dispatch(push(`/ineligible/${reason}`));
    } else if (!estimate || coverageInput?.value === "null") {
      dispatch(push("/products/insurance/wysh-builder/list"));
    } else {
      dispatch(push("/coverage-preview"));
    }
  };

  return finishEstimateFlow;
};
