import { FC, useEffect } from "react";
import { useSelector } from "react-redux";

import { getProductOfferingAttr } from "analytics/defaults";
import { productOfferEditEvent } from "analytics/productOfferEvents";
import ADFAQ from "components/ADFAQ/ADFAQ";
import ADOfferCard from "components/ADOfferCard/ADOfferCard";
import CustomerSupportCard from "components/CustomerSupportCard/CustomerSupportCard";
import Text from "components/Text/Text";
import { DynamicFormItems } from "config/types/dynamicForm.types";
import { Layout, useTranslation } from "modules";
import { useAppDispatch } from "store";
import { eventFired } from "store/actions/analytics";
import { getWyshADOffering } from "store/selectors";

import { push } from "connected-react-router";

interface IProps {
  dynamicFormItems?: DynamicFormItems[];
  onClickCta?(): void;
  onClickEdit?(offeringType: "termLifeOffering" | "adOffering"): void;
}

const CaseResultAD: FC<IProps> = props => {
  const { dynamicFormItems, onClickEdit } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const adOffering = useSelector(getWyshADOffering);
  const productOfferAttribute = useSelector(getProductOfferingAttr);

  useEffect(() => {
    dispatch(
      eventFired({
        event: "fulfillment_quote_displayed_pageview",
        attribute: { productOffering: productOfferAttribute },
      })
    );
  }, [dispatch, productOfferAttribute]);

  const subtitle = (
    <>
      {t(
        "offerings_review.ad_only.body_start",
        "We aren’t able to offer you a term life insurance policy at this time, "
      )}
      <Text
        className="result-ad__subtitle--emphasis color-primary"
        tag="p3"
        as="span"
        text={t("offerings_review.ad_only.body.emphasis.1", "BUT ")}
      />
      {t(
        "offerings_review.ad_only.body_middle",
        "you are pre-approved for the financial protection of "
      )}
      <Text
        className="result-ad__subtitle--emphasis color-primary"
        tag="p3"
        as="span"
        text={t("offerings_review.ad_only.body.emphasis.2", "accidental death insurance")}
      />
      {t("offerings_review.ad_only.body_end", ". Take a look!")}
    </>
  );

  const content = {
    title: t("offerings_review.ad_only.title", "Ok - So there’s good news and bad news"),
    subtitle,
  };

  const handleEditClick = () => {
    if (onClickEdit) {
      onClickEdit("adOffering");
    } else {
      dispatch(push("/edit-coverage/adOffering"));
    }
    dispatch(productOfferEditEvent("ad"));
  };

  return (
    <>
      <Layout
        className="result-ad"
        as="ADProductOfferingLayout"
        content={content}
        backNav={{ hasBackNav: false }}>
        {adOffering && (
          <ADOfferCard
            productOffering={adOffering}
            onClickEdit={handleEditClick}
            showPremiumCalculationModal
            dynamicFormItems={dynamicFormItems}
          />
        )}
        <CustomerSupportCard />
      </Layout>
      <ADFAQ />
    </>
  );
};

export default CaseResultAD;
