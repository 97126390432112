import "./WyshBuilderLite.scss";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { wyshBuilderBackEvent, wyshBuilderStartedEvent } from "analytics/onboardingEvents";
import ScreenLoader from "components/ScreenLoader/ScreenLoader";
import Text from "components/Text/Text";
import WyshCategoryList from "components/WyshCategoryList/WyshCategoryList";
import WyshBuilderLiteCta from "containers/WyshBuilderLite/WyshBuilderLiteCta";
import WyshesAddedDrawer from "containers/WyshesAddedDrawer/WyshesAddedDrawer";
import {
  ConditionalWrapper,
  Layout,
  useAddWyshCategories,
  useJourney,
  useNavigate,
  useTranslation,
} from "modules";
import { RootState } from "store";
import { eventFired } from "store/actions/analytics";
import { setModal } from "store/actions/modal";

import { goBack } from "connected-react-router";
import { find, get } from "lodash-es";

const WyshBuilderLite = () => {
  const wyshesItems = useSelector((state: RootState) => state.wyshes.wyshesItems);
  const categories = useSelector((state: RootState) => state.wyshCategories.categories);
  const { allocated } = useSelector((state: RootState) => state.wyshAllocations);
  const { activeCount } = useSelector((state: RootState) => state.allocationSummary);
  const hasEstimate = useSelector((state: RootState) => !!state.estimate.id);
  const wyshCategories = useAddWyshCategories();
  const { anyJourneysActive } = useJourney();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(wyshBuilderStartedEvent());
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const openAddWyshModal = (wyshId: string) => {
    const wysh = find(wyshesItems, ["id", wyshId]);
    dispatch(
      eventFired({
        event: "wysh_builder_navigation_clicked_wysh",
        experienceLocation: "ONBOARDING",
        attribute: {
          wyshType: get(wysh, "defaultName"),
          wyshCategory: get(wysh, "wyshCategory.name"),
        },
      })
    );
    dispatch(setModal("AddWyshModal", "", { wyshId }));
  };

  const onTabClick = (tabId: string) => {
    dispatch(
      eventFired({
        event: "wysh_builder_navigation_category_select",
        experienceLocation: "ONBOARDING",
        attribute: {
          wyshCategory: tabId,
        },
      })
    );
  };

  const tabsContent = {
    header: t("wysh_builder.onboarding.header", "Calculate your coverage with Wyshes"),
    subheader: t(
      "wysh_builder.onboarding.subheader",
      "Tell us what you want covered if you die (your Wyhes), and we’ll tell you how much coverage you’ll need."
    ),
    tabs: categories.map(category => {
      return {
        id: category.slug,
        list: {
          label: category.name,
          description: category.listDescription,
        },
        content: {
          header: category.prompt,
          component: (
            <WyshCategoryList
              wyshes={wyshCategories[category.slug].wyshes}
              onClick={openAddWyshModal}
              fadeIn
              showWyshTotal={true}
            />
          ),
        },
      };
    }),
    onTabClick,
  };

  const ctaMessageText = t("wysh_builder.cta.message", "Get your estimate in under a minute!");

  const handleScroll = () => {
    const totalPageHeight = document.body.scrollHeight;
    const scrollPoint = window.scrollY + window.innerHeight;
    // check if we hit the bottom of the page
    if (scrollPoint >= totalPageHeight) {
      dispatch(
        eventFired({
          event: "wysh_builder_navigation_page_bottom",
          experienceLocation: "ONBOARDING",
        })
      );
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });

  const onBackNav = () => {
    dispatch(wyshBuilderBackEvent());

    if (anyJourneysActive) {
      return navigate("/wysh-builder");
    } else {
      dispatch(goBack());
    }
  };

  if (!tabsContent.tabs.length) {
    return <ScreenLoader />;
  }

  return (
    <Layout
      as="FullWidthLayout"
      backNav={{ hasBackNav: true, replaceExitInJourneyWithBack: true, onClick: onBackNav }}
      className="wysh-build-lite">
      {!!activeCount && <WyshesAddedDrawer allocations={allocated} />}
      <div className="wysh-build-lite__mobile appear-hidden@large">
        <Layout as="ScrollingTabsLayout" backNav={{ hasBackNav: false }} content={tabsContent}>
          <div className="wysh-builder-lite__list-cta--small-wrapper">
            <WyshBuilderLiteCta
              type="wysh-list"
              dataTestId="wysh-builder-lite__list-cta--small"
              className="wysh-builder-lite__list-cta--small full-width"
            />
            <ConditionalWrapper condition={!hasEstimate}>
              <div className="wysh-builder-lite__cta--message-wrapper">
                <Text text={ctaMessageText} tag="p5" />
              </div>
            </ConditionalWrapper>
          </div>
        </Layout>
      </div>
      <div className="wysh-build-lite__desktop appear-hidden appear-initial@large">
        <Layout as="SidebarTabsLayout" backNav={{ hasBackNav: false }} content={tabsContent}>
          <WyshBuilderLiteCta
            type="wysh-list"
            dataTestId="wysh-builder-lite__list-cta--large"
            className="wysh-builder-lite__list-cta--large full-width"
          />
          <ConditionalWrapper condition={!hasEstimate}>
            <div className="wysh-builder-lite__cta--message-wrapper">
              <Text text={ctaMessageText} tag="p5" />
            </div>
          </ConditionalWrapper>
        </Layout>
      </div>
    </Layout>
  );
};

export default WyshBuilderLite;
