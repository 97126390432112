import { ComponentProps, FC, useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RouteProps, useLocation, useParams } from "react-router";

import { disclosureQuestionReceivedEvent } from "analytics/fulfillmentEvents";
import {
  savingsApplicationFormCompletedEvent,
  savingsApplicationFormStartedEvent,
} from "analytics/savings";
import {
  DynamicFormCriteriaFlowScope,
  EnumFlowTypes,
  FlowTypes,
  TitleCallback,
  isDF3KSlug,
} from "config";
import { DynamicFormFlow } from "containers/DynamicFormFlow/DynamicFormFlow";
import Flow from "containers/Flow/Flow";
import FulfillmentOutages from "containers/FulfillmentOutages/FulfillmentOutages";
import NotFound from "containers/NotFound/NotFound";
import { Layout, useJourney, useQueryParams, useTranslation } from "modules";
import { RootState, useAppDispatch } from "store";
import { initiateFinanceOnboarding } from "store/actions";
import { fetchFinanceCustomer } from "store/actions/financeCustomer";
import { finishFlow, setFormFlowIndex } from "store/actions/flow";
import { getFeatureFlagByName, selectFinanceCustomer, useParamSelector } from "store/selectors";

import classNames from "classnames";
import { includes, startCase } from "lodash-es";

import { useFlowRouteBackNav } from "./useFlowRouteBackNav";
import { useFlowRouteRedirect } from "./useFlowRouteRedirect";
import { useSkipFlowForm } from "./useSkipFlowForm";

type SlugParams = { slug: FlowTypes };
type FlowComponentProps = ComponentProps<typeof Flow>;

interface IProps extends RouteProps, SlugParams {}

export const buildFlowTitle: TitleCallback<SlugParams> = ({ match }) => {
  const formattedSlug = startCase(match?.params.slug);
  return formattedSlug;
};

const FlowRoute: FC<IProps> = props => {
  // State
  const [flowFormOffline, setFlowFormOffline] = useState(false);

  // Hooks
  const { t } = useTranslation();
  const params = useParams<SlugParams>();
  const location = useLocation<{ flowScope?: DynamicFormCriteriaFlowScope }>();
  const dispatch = useAppDispatch();
  const { queryParams } = useQueryParams();
  const { nextStepCallback, previousStepCallback } = useJourney();

  // Selectors
  const flowTracker = useSelector((state: RootState) => state.analyticsTracker);
  const isFirstForm = useSelector((state: RootState) => state.formFlow.formIndex <= 1);
  const hasFinanceCustomer = !!useSelector(selectFinanceCustomer)?.id;
  const skipFundingFeatureFlag = useParamSelector(
    getFeatureFlagByName,
    "wysh-financial-skip-funding"
  );

  // Memoized constants
  const slug = useMemo(() => props.slug ?? params.slug ?? "", [params.slug, props.slug]);
  const isEditFlow = useMemo(() => queryParams.get("edit") === "true", [queryParams]);
  const flowScope = useMemo(() => (location.state || {})?.flowScope, [location.state]);

  // Hook Callbacks
  const { skipFlowForm } = useSkipFlowForm(slug);
  const { backNavOverride } = useFlowRouteBackNav(slug);
  const { redirectToNextPage } = useFlowRouteRedirect(slug);

  const onTerminal = useCallback(async () => {
    if (slug === "savings-cdd") {
      await dispatch(fetchFinanceCustomer());
    }
    if (slug === "savings-default-application") {
      dispatch(savingsApplicationFormCompletedEvent());
      if (!hasFinanceCustomer && skipFundingFeatureFlag) {
        await dispatch(initiateFinanceOnboarding({}));
      }
    }
    await dispatch(finishFlow({ flowTracker }));
    await nextStepCallback(() => redirectToNextPage(), false)();
  }, [
    dispatch,
    slug,
    flowTracker,
    hasFinanceCustomer,
    skipFundingFeatureFlag,
    nextStepCallback,
    redirectToNextPage,
  ]);

  // Callbacks
  const onNextForm: FlowComponentProps["nextFormCallback"] = useCallback(
    async flowFormResponse => {
      const hasUnderwritingErrorCode = ["AURA_COMMON_ERROR", "UNDERWRITING_DISABLED"].includes(
        flowFormResponse?.code || ""
      );
      setFlowFormOffline(hasUnderwritingErrorCode);
      skipFlowForm();
      dispatch(disclosureQuestionReceivedEvent());
    },
    [dispatch, skipFlowForm]
  );

  const navigateToPreviousStep = useCallback(() => {
    if (!isFirstForm) {
      dispatch(setFormFlowIndex("back"));
    } else {
      const callback = previousStepCallback();
      callback();
    }
  }, [dispatch, isFirstForm, previousStepCallback]);

  // BackNav Props
  const backNav = useMemo(
    () => ({
      hasBackNav: true,
      replaceExitInJourneyWithBack: true,
      onClick: navigateToPreviousStep,
      ...backNavOverride,
    }),
    [navigateToPreviousStep, backNavOverride]
  );

  // Effects
  useEffect(() => {
    if (slug === "savings-default-application") {
      dispatch(savingsApplicationFormStartedEvent());
    }
  }, [dispatch, slug]);

  if (flowFormOffline) {
    return <FulfillmentOutages type="unscheduled_maintenance" />;
  }

  const classes = classNames("flow-container", { "dynamic-form-container": isDF3KSlug(slug) });

  return (
    <Layout
      className={classes}
      as={isDF3KSlug(slug) ? "FullWidthLayout" : "TwoColumnLayout"}
      backNav={backNav}
      content={{}}
      renderFallback={!includes(EnumFlowTypes, slug)}
      fallback={NotFound}>
      {isDF3KSlug(slug) ? (
        <DynamicFormFlow flowSlug={slug} flowScope={flowScope} sessionFromStart />
      ) : (
        <Flow
          terminalCallback={onTerminal}
          nextFormCallback={onNextForm}
          sessionFromStart
          slug={slug}
          ctaText={isEditFlow ? t("cta.save", "SAVE") : ""}
        />
      )}
    </Layout>
  );
};

export default FlowRoute;
