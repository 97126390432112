import { useState } from "react";

import { ErrorCodes, IAppError } from "config";

const AURA_ERROR_CODES: ErrorCodes[] = ["AURA_COMMON_ERROR", "UNDERWRITING_DISABLED"];

export const useDynamicFormExpectedError = () => {
  //  STATE
  const [auraOffline, setAuraOffline] = useState(false);

  // METHODS
  const checkErrorCode = (error: IAppError) => {
    const hasAuraExpectedErrorCode = AURA_ERROR_CODES.includes(error?.code as ErrorCodes);

    if (hasAuraExpectedErrorCode) {
      setAuraOffline(hasAuraExpectedErrorCode);
    }

    return hasAuraExpectedErrorCode;
  };

  return { auraOffline, checkErrorCode };
};
