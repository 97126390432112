import { RootState } from "store";

import { createSelector } from "@reduxjs/toolkit";

import { getIsIdentified } from "./getIsIdentified";
import { getIsLoggedIn } from "./getIsLoggedIn";

const getHasEstimate = (state: RootState) => !!state.estimate.id;

export const getIsPastEstimation = createSelector(
  getHasEstimate,
  getIsIdentified,
  getIsLoggedIn,
  (hasEstimate, isIdentified, isLoggedIn) => (isIdentified && hasEstimate) || isLoggedIn
);
