import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";

import ScreenLoader from "components/ScreenLoader/ScreenLoader";
import { BillingFrequency, IProductOffering } from "config/types";
import CoverageEditor from "containers/CoverageEditor/CoverageEditor";
import { useCoverageMetadata } from "modules/hooks/useCoverageMetadata";
import { RootState } from "store";
import { getCurrentUserEstimate } from "store/actions/estimate";
import { getProductOfferings } from "store/actions/product-offerings";
import { getQuote } from "store/actions/quote";
import { getWyshAllocations, getWyshAllocationSummary } from "store/actions/wyshes";
import { IEstimateState } from "store/reducers/estimate";
import { IQuoteState } from "store/reducers/quote";
import { getEstimate, getWyshADOffering, getWyshTermLifeOffering } from "store/selectors/";
import { sendEstimateBuilderComplete } from "utils/analytics";

import { withTransaction } from "@elastic/apm-rum-react";
import { defaultTo, includes } from "lodash-es";

export type CoverageType = "quote" | "estimate" | "policy" | "adOffering" | "termLifeOffering";

type RouteParams = { type: CoverageType; billingFrequency: BillingFrequency };

interface IProps extends Partial<RouteParams> {
  onSave?(): void;
  onBack?(): void;
}

const CoverageEditorRoute: FC<IProps> = props => {
  const params = useParams<RouteParams>();
  const type = defaultTo(props.type, params.type);
  const billingFrequencyProp = defaultTo(props.billingFrequency, params.billingFrequency);
  const billingFrequency = defaultTo(billingFrequencyProp, "monthly");
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);

  // Redux store
  const quote = useSelector((state: RootState) => state.quote);
  const estimate = useSelector(getEstimate);
  const adOffering = useSelector(getWyshADOffering);
  const termLifeOffering = useSelector(getWyshTermLifeOffering);

  const user = useSelector((state: RootState) => state.user);
  const allocationSummary = useSelector((state: RootState) => state.allocationSummary);

  let coverage: IEstimateState | IQuoteState | IProductOffering = quote;
  switch (type) {
    case "estimate":
      coverage = estimate;
      break;
    case "adOffering":
      if (adOffering) {
        coverage = adOffering;
      }
      break;
    case "termLifeOffering":
      if (termLifeOffering) {
        coverage = termLifeOffering;
      }
      break;
  }

  const { metadataAmount, metadataDuration } = useCoverageMetadata(coverage, type);

  const fetchCoverageData = useCallback(async () => {
    await dispatch(getWyshAllocations());
    await dispatch(getWyshAllocationSummary());

    if (type === "estimate") {
      await dispatch(getCurrentUserEstimate());
    }

    if (type === "quote") {
      await dispatch(getQuote());
    }

    if (includes(["adOffering", "termLifeOffering"], type)) {
      await dispatch(getProductOfferings());
    }

    setLoaded(true);
  }, [dispatch, type]);

  useEffect(() => {
    fetchCoverageData();
  }, [fetchCoverageData]);

  useEffect(() => {
    if (type === "estimate") {
      sendEstimateBuilderComplete({
        allocationSummary,
        dispatch,
        estimate,
        userId: user.id,
        eventName: "estimate_coverage_editor_display",
      });
    }
  }, [type]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!loaded) {
    return <ScreenLoader />;
  }

  if (!["quote", "estimate", "adOffering", "termLifeOffering"].includes(type)) {
    return <Redirect to="/" />;
  }

  return (
    <CoverageEditor
      billingFrequency={billingFrequency}
      coverage={coverage}
      isExtendible={
        type !== "estimate"
          ? (coverage as IProductOffering | IQuoteState).extensionAvailable
          : false
      }
      metadataAmount={metadataAmount}
      metadataDuration={metadataDuration}
      onBack={props.onBack}
      onSave={props.onSave}
      type={type}
    />
  );
};

export default withTransaction("CoverageEditorRoute", "component")(CoverageEditorRoute);
