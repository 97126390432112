import { useEffect, useState } from "react";

import ScreenLoader from "components/ScreenLoader/ScreenLoader";
import { DynamicFormCriteriaFlowScope, FlowTypes, IFlow } from "config";
import { DynamicForm } from "containers/DynamicForm/DynamicForm";
import { getFlowSlug } from "containers/Flow/Flow";
import FulfillmentOutages from "containers/FulfillmentOutages/FulfillmentOutages";
import NotFound from "containers/NotFound/NotFound";
import { useDynamicFormExpectedError } from "modules";
import { useAppDispatch, useAppSelector } from "store";
import { fetchDynamicForm, initFormFlow } from "store/actions/flow";
import { setJourneyProgressPercentOverride } from "store/actions/journey";
import { setModal } from "store/actions/modal";
import { selectDynamicFormsByLayout } from "store/selectors";

import { defaultTo } from "lodash-es";

/**
 * Manages retrieval of DynamicForms in DF3K Flow
 * @param {string} flowSlug - IFlow.slug to start flow session, createFlowSession
 * @param {DynamicFormCriteriaFlowScope} [flowScope] - DynamicFormCriteriaFlowScope to fetch DynamicForm
 * @param {boolean} [sessionFromStart] - If true, start a new flow session
 * @return {React.ReactElement} - DynamicForm component using IDynamicForm data
 */

export function DynamicFormFlow(props: IProps) {
  const { flowSlug, flowScope, sessionFromStart, ...rest } = props;

  // HOOKS
  const dispatch = useAppDispatch();
  const { dynamicForm, modalForm } = useAppSelector(selectDynamicFormsByLayout);
  const { auraOffline, checkErrorCode } = useDynamicFormExpectedError();

  // LOCAL DERIVED VARIABLES
  const flowSlugLatest = getFlowSlug(flowSlug, {});

  // LOCAL STATE
  const [loading, setLoading] = useState(false);
  const [flow, setFlow] = useState<Pick<IFlow, "id" | "slug" | "terminalBehavior"> | null>(null);

  useEffect(() => {
    const fetchFormBySlug = async () => {
      try {
        setLoading(true);
        const response = await dispatch(
          initFormFlow({ sessionFromStart, slug: flowSlugLatest })
        ).unwrap();
        const { flow, flowSession } = response;
        const flowScopeMap: Partial<Record<FlowTypes, DynamicFormCriteriaFlowScope>> = {
          beneficiary: "application",
          "insurance-profile": "policy_holder",
          "onboarding-intro": "application",
          "post-underwriting": "application",
        };
        const dynamicFormFlowScope = defaultTo(flowScope, flowScopeMap[flowSlug]);
        await dispatch(
          fetchDynamicForm({
            criteria: { flowScope: dynamicFormFlowScope },
            flowSessionId: flowSession.id,
          })
        ).unwrap();

        setFlow(flow);
      } catch (error) {
        const isExpectedFormError = checkErrorCode(error);

        if (!isExpectedFormError) {
          dispatch(setModal("GeneralErrorModal", "", { error }));
        }
      } finally {
        setLoading(false);
      }
    };

    fetchFormBySlug();
  }, [dispatch, flowSlug]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const FINAL_DYNAMIC_FORM_SLUGS = [
      "underwriting-result-denied",
      "underwriting-result-failed",
      "underwriting-result-manual",
    ];

    const isFinalDynamicFormSlug = FINAL_DYNAMIC_FORM_SLUGS.includes(dynamicForm?.slug);

    if (isFinalDynamicFormSlug) {
      dispatch(setJourneyProgressPercentOverride(100));
    } else {
      dispatch(setJourneyProgressPercentOverride(undefined));
    }
  }, [dispatch, dynamicForm.slug]);

  // RENDER
  if (loading) {
    return <ScreenLoader />;
  }

  if (auraOffline) {
    return <FulfillmentOutages type="unscheduled_maintenance" />;
  }

  if ((!dynamicForm.id && dynamicForm.hasLoaded) || !flow) {
    return <NotFound />;
  }

  return (
    <>
      {(dynamicForm.isLoading || modalForm?.isLoading) && <ScreenLoader />}
      {modalForm && <DynamicForm dynamicForm={modalForm} flowSlug={flow.slug} />}
      <DynamicForm {...rest} dynamicForm={dynamicForm} flowSlug={flow.slug} />
    </>
  );
}

/* ------------------    TYPES    ------------------ */

interface IProps {
  flowSlug: FlowTypes;
  flowScope?: DynamicFormCriteriaFlowScope;
  sessionFromStart?: boolean;
}
