import { usePurchasePolicyLeadingNavigation } from "modules";
import { useAppSelector } from "store/index";
import { selectDynamicFormsByLayout } from "store/selectors";

export const useLeadingNavigationContent = () => {
  // HOOKS
  const { dynamicForm } = useAppSelector(selectDynamicFormsByLayout);
  const purchasePolicyLeadingNavigation = usePurchasePolicyLeadingNavigation(
    dynamicForm.leadingNavigationButton
  );

  // Return special use cases by form
  switch (dynamicForm.slug) {
    case "purchase-policy":
      return purchasePolicyLeadingNavigation;
    default:
      return dynamicForm.leadingNavigationButton;
  }
};
