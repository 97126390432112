import "./PurchasePolicyLayout.scss";

import { IDynamicForm } from "config";
import PaymentConfirmation from "containers/PaymentConfirmation/PaymentConfirmation";
import PurchasePolicyContainer from "containers/PurchasePolicy/PurchasePolicy";

interface IProps {
  items: IDynamicForm["items"];
  slug: IDynamicForm["slug"];
}

export function PurchasePolicyLayout({ items, slug }: IProps) {
  if (slug === "purchase-policy") {
    return <PurchasePolicyContainer dynamicFormItems={items} />;
  }

  // Purchase Success Screen
  return <PaymentConfirmation dynamicFormItems={items} />;
}
