import { useModal } from "modules";

export const useShowCloseAccountFailedModal = () => {
  const openModal = useModal();

  const showCloseAccountFailedCommand = () => {
    openModal("CloseAccountFailedModal");
  };

  return showCloseAccountFailedCommand;
};
