import { gql } from "config/graphql";
import { IProfile, IUser } from "config/types";
import { CONSTELLATION_FIELDS, CUSTOMER_FIELDS, FEATURE_FLAG_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

interface IFetchPreVerifiedReseponse {
  data: {
    me: IVerifiedInitialData;
  };
}

export const fetchPreVerifiedInitialData = (): Promise<IFetchPreVerifiedReseponse> => {
  const client = getApolloClient();

  const query = gql`
    ${FEATURE_FLAG_FIELDS}
    query fetchPreVerifiedInitialData {
      me {
        id
        status
        emails {
          id
          email
          status
        }
        phoneNumbers {
          id
          mask
          status
          createdAt
        }
        state {
          id
          name
          alpha2
        }
        activeConstellations {
          activeInsuranceMembership {
            id
            coverageDetails {
              maximumApprovedCoverageAmount
              maximumApprovedTermDuration
              minimumApprovedCoverageAmount
              minimumApprovedTermDuration
              requestedCoverageAmount
              requestedTermDuration
              product {
                slug
              }
            }
            latestEstimateDatum {
              id
              termDuration
              termUnit
              amount
              health
              tobacco
              insuredsAgeAtTerm
              premiums {
                amount
                billingFrequency
              }
            }
          }
          createdAt
          featureFlags {
            ...FeatureFlagFields
          }
          id
          label
        }
      }
    }
  `;

  return client.query({
    fetchPolicy: "no-cache",
    query,
  });
};

interface IVerifiedInitialData extends IUser, IProfile {}

interface IFetchVerifiedIntialResponse {
  data: {
    me: IVerifiedInitialData;
  };
}

export const fetchVerifiedInitialData = (): Promise<IFetchVerifiedIntialResponse> => {
  const client = getApolloClient();

  const query = gql`
    ${CONSTELLATION_FIELDS}
    ${CUSTOMER_FIELDS}
    query fetchVerifiedInitialData {
      me {
        activeConstellations {
          ...ConstellationFields
        }
        id
        age
        birthdate
        age
        status
        createdAt
        firstName
        lastName
        middleName
        suffix
        emails {
          email
          status
        }
        customer {
          ...CustomerFields
        }
        phoneNumbers {
          id
          phoneNumber
          createdAt
          mask
          status
        }
        activeAddress {
          formattedDisplay
          street1
          street2
          city
          state
          postalCode
          country {
            id
            name
            alpha3
            hasStates
            hasPostalCode
            postalCodeFormat
          }
          stateObject {
            id
            name
            alpha2
          }
        }
      }
    }
  `;

  return client.query({ query });
};
