import { gql } from "config/graphql";
import { CLASSIFICATION_FIELDS } from "graphql/fragments/classification";
import { PAYOUT_DETAILS_FIELDS } from "graphql/fragments/payoutDetails";
import { PRODUCT_DETAIL_FIELDS } from "graphql/fragments/product";

export const POLICY_FIELDS = gql`
  ${CLASSIFICATION_FIELDS}
  ${PRODUCT_DETAIL_FIELDS}
  ${PAYOUT_DETAILS_FIELDS}
  fragment PolicyFields on Policy {
    id
    label
    amount
    status
    termDuration
    termUnit
    insuredsAgeAtTerm
    effectiveAt
    pausedAt
    terminatesAt
    lapsesAt
    effectiveUntil
    classification {
      ...ClassificationFields
    }
    product {
      activePlan {
        id
        amount
        billingFrequency
        nextPayment {
          date
          invoiceId
          estimated
        }
      }
    }
    productV2 {
      ...ProductFields
    }
    payoutDetails {
      ...PayoutDetailsFields
    }
    documents {
      id
      name
      description
      slug
      html {
        url
        fileName
      }
      pdf {
        url
        fileName
      }
      version
    }
  }
`;
