import "./TriviaLayout.scss";

import { useCallback, useEffect, useState } from "react";

import { IDynamicForm, ButtonFormItem } from "config";
import { useButtonAction } from "containers/DynamicForm/useButtonAction";
import { useRetryNextForm } from "containers/DynamicForm/useButtonCommand/useRetryNextForm";
import TriviaContainer from "containers/Trivia/Trivia";
import { PollingStatus } from "modules/hooks/usePolling";
import CoverageDeniedRoute from "router/CoverageDeniedRoute/CoverageDeniedRoute";
import { useAppDispatch } from "store";
import { getProductOfferings } from "store/actions/product-offerings";

import { find } from "lodash-es";

interface IProps {
  items: IDynamicForm["items"];
  slug: IDynamicForm["slug"];
}

export function TriviaLayout({ items }: IProps) {
  //HOOKS
  const dispatch = useAppDispatch();
  const retryNextFormCommand = useRetryNextForm();

  // LOCAL STATE
  const [pollingStatus, setPollingStatus] = useState<PollingStatus>("polling");
  const [triviaInProgress, setTriviaInProgress] = useState(true);

  // LOCAL DERIVED VARIABLES
  const underwritingResultsButtonItem = items.find(
    dynamicFormItem => dynamicFormItem.key === "underwriting-results-button"
  ) as ButtonFormItem;

  const underwritingResultsButtonActions = useButtonAction(
    underwritingResultsButtonItem?.content?.actions || []
  );

  // METHODS
  const onComplete = async () => {
    try {
      dispatch(getProductOfferings());
      await underwritingResultsButtonActions({});
    } catch (error) {
      return error;
    } finally {
      setTriviaInProgress(false);
    }
  };

  // MOUNT EFFECTS
  const pollNextForm = useCallback(async () => {
    try {
      const nextFormRetryAction = find(underwritingResultsButtonItem?.content?.actions, {
        command: { arguments: [{ name: "retryTimeout" }], destination: "nextForm" },
      });

      if (nextFormRetryAction) {
        await retryNextFormCommand(nextFormRetryAction.command.arguments, {});
      }

      setPollingStatus("done");
    } catch (error) {
      setPollingStatus("timed-out");
    }
  }, [retryNextFormCommand, underwritingResultsButtonItem]);

  useEffect(() => {
    pollNextForm();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  // RENDER
  if (!triviaInProgress) {
    return <CoverageDeniedRoute type="failed" />;
  }

  return (
    <TriviaContainer
      isFailedQuote={pollingStatus === "timed-out"}
      isQuoteReady={pollingStatus !== "polling"}
      onComplete={onComplete}
      requestStatus={pollingStatus}
    />
  );
}
