import { BusinessSlugTypes } from "config";
import { RootState } from "store";

import { createSelector } from "@reduxjs/toolkit";

export const getFinanceMembership = (state: RootState) => state.financeMembership;
export const getInsuranceMembership = (state: RootState) => state.insuranceMembership;
export const getActivePolicy = (state: RootState) => state.policy;

export const getFinanceMembershipId = createSelector(getFinanceMembership, financeMembership => {
  return financeMembership.id;
});

export const getInsuranceMembershipId = createSelector(
  getInsuranceMembership,
  insuranceMembership => insuranceMembership.id
);

export const getMembershipMetadata = createSelector(getFinanceMembership, financeMembership => {
  return financeMembership?.metadata || {};
});

export const getActiveMembershipBusinesses = createSelector(
  getFinanceMembership,
  getInsuranceMembership,
  (financeMember, insuranceMember) => {
    const activeMembershipsByBusiness: Record<BusinessSlugTypes, boolean> = {
      "wysh-financial": !!financeMember.id,
      "wysh-insurance": !!insuranceMember.id,
    };

    return activeMembershipsByBusiness;
  }
);
