import { RootState } from "store";

import { createSelector } from "@reduxjs/toolkit";

const getCoverageDetails = (state: RootState) => state.coverageDetails;
const getLatestEstimateDatum = (state: RootState) => state.estimate;

export const getEstimate = createSelector(
  getCoverageDetails,
  getLatestEstimateDatum,
  (coverageDetails, estimate) => {
    const termLifeDetails = coverageDetails["wysh-term-life"];
    const result = { ...estimate };

    const isOverMaximumCoverageAmount =
      estimate.amount &&
      termLifeDetails.maximumApprovedCoverageAmount &&
      estimate.amount > termLifeDetails.maximumApprovedCoverageAmount;
    const isUnderMinimumCoverageAmount =
      estimate.amount &&
      termLifeDetails.minimumApprovedCoverageAmount &&
      estimate.amount < termLifeDetails.minimumApprovedCoverageAmount;

    if (isOverMaximumCoverageAmount) {
      result.amount =
        termLifeDetails.requestedCoverageAmount || termLifeDetails.maximumApprovedCoverageAmount;
    } else if (isUnderMinimumCoverageAmount) {
      result.amount =
        termLifeDetails.requestedCoverageAmount || termLifeDetails.minimumApprovedCoverageAmount;
    } else {
      result.amount = estimate.amount;
    }

    const isOverMaximumCoverageDuration =
      estimate.termDuration &&
      termLifeDetails.maximumApprovedTermDuration &&
      estimate.termDuration > termLifeDetails.maximumApprovedTermDuration;
    const isUnderMinimumCoverageDuration =
      estimate.termDuration &&
      termLifeDetails.minimumApprovedTermDuration &&
      estimate.termDuration < termLifeDetails.minimumApprovedTermDuration;

    if (isOverMaximumCoverageDuration) {
      result.termDuration =
        termLifeDetails.requestedTermDuration || termLifeDetails.maximumApprovedTermDuration;
    } else if (isUnderMinimumCoverageDuration) {
      result.termDuration =
        termLifeDetails.requestedTermDuration || termLifeDetails.minimumApprovedTermDuration;
    } else {
      result.termDuration = estimate.termDuration;
    }

    return result;
  }
);
