import { gql } from "config/graphql";

export const BENEFICIARY_CONTACT_FIELDS = gql`
  fragment BenefiaryContactFields on BeneficiariesContact {
    __typename
    ... on Individual {
      id
      firstName
      middleInitial
      lastName
      suffix
      relationship
      birthdate
      countryCode
      phoneNumber
      email
      parentGuardianInfo
      taxIdSet
      requiredAddress {
        id
        street1
        street2
        city
        state
        postalCode
      }
    }
    ... on Minor {
      id
      firstName
      middleInitial
      lastName
      suffix
      relationship
      birthdate
      countryCode
      phoneNumber
      email
      parentGuardianInfo
      taxIdSet
      requiredAddress {
        id
        street1
        street2
        city
        state
        postalCode
      }
    }
    ... on Organization {
      id
      name
      countryCode
      phoneNumber
      email
      businessType
      taxIdSet
      requiredAddress {
        id
        street1
        street2
        city
        state
        postalCode
      }
    }
    ... on Trust {
      id
      name
      firstName
      lastName
      countryCode
      phoneNumber
      email
      dateOfTrust
      trusteeTaxIdSet
      nullableAddress {
        id
        street1
        street2
        city
        state
        postalCode
      }
    }
  }
`;

export const BENEFICIARY_ASSIGNED_ENTITY_FIELDS = gql`
  fragment BeneficiaryAssignedEntityFields on BeneficiariesAssignedEntity {
    entityType: __typename
    ... on InsuranceMembership {
      id
    }
    ... on Policy {
      id
    }
  }
`;

export const BENEFICIARY_FIELDS = gql`
  ${BENEFICIARY_ASSIGNED_ENTITY_FIELDS}
  ${BENEFICIARY_CONTACT_FIELDS}
  fragment BeneficiaryFields on Beneficiary {
    allocation
    assignedEntity {
      ...BeneficiaryAssignedEntityFields
    }
    contact {
      ...BenefiaryContactFields
    }
    createdAt
    id
    responsibility
  }
`;
