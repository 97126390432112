import "./FormError.scss";

import { CSSProperties, FC } from "react";

import Text from "components/Text/Text";
import { ClassName, ValueOrArray } from "config/types";

import classNames from "classnames";
import { flatten } from "lodash-es";

type IProps = {
  error: ValueOrArray<string>;
  dataTestId?: string;
  className?: ClassName;
  style?: CSSProperties;
};

const FormError: FC<IProps> = ({ error, dataTestId, className, style }) => {
  const errorMessages = flatten([error]);
  const classes = classNames(className, "form-error", { "form-error--active": !!error });

  const errors = errorMessages.map((message, index) => {
    return (
      <span data-testid={dataTestId} key={index}>
        {message}
      </span>
    );
  });

  return (
    <Text tag="p6" className={classes} style={style}>
      {errors}
    </Text>
  );
};

export default FormError;
