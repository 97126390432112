import { verifyEmailMutation } from "graphql/mutations/profile";
import { fetchProfile, fetchProfileReviewPersonalInfo } from "graphql/queries/profile";
import { RootState } from "store";
import { getIsLoggedIn } from "store/selectors";
import { getAppError } from "utils/error";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash-es";

/* ------------       THUNKS      ------------------ */

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await fetchProfile();
      const profile = get(data, "me");

      return profile;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      const isUserAuthorized = getIsLoggedIn(state);

      if (!isUserAuthorized) {
        return false;
      }
    },
  }
);

export const getProfileReviewPersonalInfo = createAsyncThunk(
  "profile/getProfileReviewPersonalInfo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchProfileReviewPersonalInfo();

      return response.data.me;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "profile/verifyEmail",
  async (input: { token: string }, { rejectWithValue }) => {
    try {
      const response = await verifyEmailMutation(input.token);
      return { response };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
