import BlobChatAgent from "assets/icons/BlobChatAgent/BlobChatAgent.svg";
import BlobPayLess from "assets/icons/BlobPayLess/BlobPayLess.svg";
import BlobSorry from "assets/icons/BlobSorry/BlobSorry.svg";
import BlobStethoscope from "assets/icons/BlobStethoscope/BlobStethoscope.svg";
import BenefitPeace from "assets/images/ads/BenefitPeace.svg";
import { useTranslation } from "modules";

// Hook to return select multiple ads content

type AdContent = {
  title: string;
  description: string;
  imgSrc: string;
};

export const useMultipleAdsData = () => {
  const { t } = useTranslation();

  const coverageEditorAds: AdContent[] = [
    {
      title: t("coverage_editor.ad.item_1.title", "FLEXIBLE COVERAGE"),
      description: t(
        "coverage_editor.ad.item_1.description",
        "Life changes all the time. Wysh coverage can change with it – whether you want to add more to cover a growing family, or need less as you pay off your debts."
      ),
      imgSrc: BlobPayLess,
    },
    {
      title: t("coverage_editor.ad.item_3.title", "BETTER CUSTOMER SERVICE"),
      description: t(
        "coverage_editor.ad.item_3.description",
        "Our team of Wysh Granters are right here in the U.S. to better give the service you need. And if you do die, they can help your loved ones can get paid in days not weeks."
      ),
      imgSrc: BlobChatAgent,
    },
    {
      title: t("coverage_editor.ad.item_4.title", "NO MEDICAL EXAMS"),
      description: t(
        "coverage_editor.ad.item_4.description",
        "Hate needles? No problem. We provide fully digital health assessments. Medical exams aren’t required to purchase a Wysh plan - just a few health questions."
      ),
      imgSrc: BlobStethoscope,
    },
    {
      title: t("coverage_editor.ad.item_5.title", "INSTANT COVERAGE"),
      description: t(
        "coverage_editor.ad.item_5.description",
        "The protection you crave is just a few clicks away. From application to approval, you can get a Wysh membership in minutes."
      ),
      imgSrc: BenefitPeace,
    },
    {
      title: t("coverage_editor.ad.item_6.title", "CANCEL ANYTIME"),
      description: t(
        "coverage_editor.ad.item_6.description",
        "Sign up for Wysh today and if you don’t like it, you can get a 100% refund if you cancel in the first 10 days."
      ),
      imgSrc: BlobSorry,
    },
  ];

  return { coverageEditorAds };
};
