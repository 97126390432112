/*
..................
~~~ INTERFACES ~~~
..................
*/

export interface GqlError {
  message: string;
  extensions: GqlErrorExtension;
}

export interface GqlErrorExtension {
  full_message: string;
  validation_errors?: {
    model: string;
    errors: GqlValidationError[];
  };
}

export interface GqlValidationError {
  field: string;
  error: string;
  message: string;
}

export interface IAppError {
  code: string;
  fullMessage: string;
  validationErrors?: GqlValidationError[];
}

/*
..................
~~~~~ ENUMS ~~~~~
..................
*/

export enum IErrorCodeType {
  ACCESS_CODE_EXPIRED,
  ACCESS_CODE_INCORRECT,
  AURA_COMMON_ERROR,
  AUTHENTICATION_TENTH_ATTEMPT,
  AUTHENTICATION_THIRD_ATTEMPT,
  BLOCKED,
  CANNOT_REUSE_PASSWORD,
  CREDENTIAL_INCORRECT,
  CREDENTIAL_INVALID,
  DEVICE_UNREGISTERED,
  DEVICE_VERIFICATION_INCORRECT,
  DYNAMIC_FORMS_POLLING,
  FINANCE_APPLICATION_DISABLED,
  GRAPH_API_UNAUTHORIZED,
  INVALID_DATE,
  PASSWORD_MISMATCH,
  PASSWORD_PREVIOUSLY_USED,
  PHONE_NUMBER_MISSING,
  PHONE_NUMBER_NOT_VERIFIED,
  REQUIRED_INPUT_MISSING,
  STANDARD_ERROR,
  TOKEN_INVALID,
  TOKEN_PURPOSE_MISMATCH,
  UNSUCCESSFUL_SESSION_VERIFICATION,
  UNDERWRITING_DISABLED,
  USER_DECOMMISSIONED,
}

/*
..................
~~~~~ TYPES ~~~~~
..................
*/

export type ErrorCodes = keyof typeof IErrorCodeType;
