import { IJourneyStep } from "config/types";

import { get, mapKeys } from "lodash-es";
import { v4 } from "uuid";

interface IJourneyMock {
  id: string;
  name: string;
  slug: string;
  steps: IJourneyStep[];
  completed?: boolean;
  parent?: string;
  context?: "onboarding" | "other";
  data?: Record<string, unknown>;
  progressStepKeys?: string[];
}

interface IJourneyPayload {
  id: string;
  name: string;
  slug: string;
  steps: IJourneyStep[];
  content?: string;
  context?: string;
  paths?: { entry?: string; exit?: string };
  progressStepKeys?: string[];
}

const fulfillmentId = "fulfillment";

// DEPRECATED on v1.10.0 - journeyIds no longer used
const onboardingId = "onboarding";
const singleWyshOnboardingId = "single-wysh-onboarding";
const coverageCollectionOnboardingId = "coverage_collection_onboarding";

// Deprecated journeys - end journey and land user on exit path
export const deprecatedJourneys: string[] = [
  onboardingId,
  singleWyshOnboardingId,
  coverageCollectionOnboardingId,
];

const fulfillmentJourney: IJourneyPayload = {
  id: fulfillmentId,
  name: "Fulfillment Journey",
  content: "fulfillment",
  slug: "fulfillment",
  paths: {
    entry: "/fulfillment",
    exit: "/",
  },
  steps: [
    {
      name: "Registration",
      key: "registration",
      type: "section",
      conditions: ["auth.authenticated", "auth.sessionVerified", "featureFlags.skipPhoneNumber"],
    },
    {
      name: "Onboarding Intro",
      key: "onboarding-intro",
      type: "flow",
      conditions: "user.hasValidProductOffers",
    },
    {
      name: "Aura Flow",
      key: "aura-interview",
      type: "flow",
      conditions: "user.hasValidProductOffers",
    },
    {
      name: "Post Underwriting",
      key: "post-underwriting",
      type: "flow",
    },
  ],
  progressStepKeys: [
    "honesty-pledge",
    "honesty-pledge-accidental-death",
    "honesty-pledge-term-life-only",
    "insurance-profile-form",
    "insurance-profile-review-screen",
    "beneficiary-intro",
    "have_a_license",
    "dmv_lic_nmbr",
    "us_citizen",
    "applied_within_12_months",
    "applicant_own_anything",
    "existing_policy_wysh",
    "employed_status",
    "employ_prosport",
    "employed_status_military",
    "income",
    "activity",
    "tobacco",
    "marijuana",
    "height",
    "weight",
    "medical_history_1",
    "medical_history_2",
    "substance_usage",
    "hiv_status",
    "covid_hospitalized",
    "medical_history_family",
    "driving_history",
    "criminal_history",
    "aura-review",
    "quote-review-term-life",
    "about-accidental-death",
    "purchase-policy",
  ],
};

const reinstatementFulfillmentJourney: IJourneyPayload = {
  id: "reinstatementFulfillment",
  name: "Reinstatement Fulfillment Journey",
  content: "fulfillment",
  slug: "fulfillment",
  paths: {
    entry: "/fulfillment",
    exit: "/",
  },
  steps: [
    {
      name: "Unified Insurance Profile",
      key: "insurance-profile",
      type: "flow",
      conditions: "user.hasValidReinstatementProductOffer",
    },
    {
      name: "Aura Flow",
      key: "aura-interview",
      type: "flow",
      conditions: "user.hasValidReinstatementProductOffer",
    },
    {
      name: "Quote Review",
      key: "quote-review-route",
      type: "screen",
    },
    {
      name: "Purchase Reinstated Policy",
      key: "purchase-reinstated-policy",
      type: "screen",
      navigation: {
        back: {
          key: "quote-review",
          conditions: "user.doesNotHavePolicyOrPayment",
        },
      },
    },
    {
      name: "Policy Confirmation",
      key: "payment-confirmation",
      type: "screen",
    },
  ],
  progressStepKeys: [
    "insurance-profile-form",
    "insurance-profile-review-screen",
    "have_a_license",
    "dmv_lic_nmbr",
    "us_citizen",
    "applied_within_12_months",
    "applicant_own_anything",
    "existing_policy_wysh",
    "employed_status",
    "employ_prosport",
    "employed_status_military",
    "income",
    "activity",
    "tobacco",
    "marijuana",
    "height",
    "weight",
    "medical_history_1",
    "medical_history_2",
    "substance_usage",
    "hiv_status",
    "covid_hospitalized",
    "medical_history_family",
    "driving_history",
    "criminal_history",
    "aura-review",
    "quote-review-route",
    "purchase-reinstated-policy",
  ],
};

// TODO: Figure out a better way to do this?
export const exitPathOverrides = {
  "onboarding-journey": () => "/products/insurance/welcome",
  "single-wysh-onboarding-journey": () => "/products/insurance/welcome",
  "coverage-collection-onboarding-journey": () => "/products/insurance/welcome",
};

export const journeys: IJourneyPayload[] = [fulfillmentJourney, reinstatementFulfillmentJourney];

export const mockJourney = (id = v4(), data: Record<string, unknown> = {}): IJourneyMock => {
  const mappedJourneys = mapKeys(journeys, "id");
  const defaultJourney = { id };
  const journey = get(mappedJourneys, id, defaultJourney);
  return { ...journey, data } as IJourneyMock;
};
