import { EnumRetryArgumentName, ErrorCodes, IApiArgument } from "config";

import { ObservableQuery } from "@apollo/client";
import { GraphQLError } from "graphql";
import { reduce } from "lodash-es";
import { DateTime } from "luxon";

import { delay } from "./delay";
import { poll } from "./poll";

const POLLING_ERROR_CODES: ErrorCodes[] = ["DYNAMIC_FORMS_POLLING"];

export const retryQuery = async (
  watchQuery: ObservableQuery,
  retryArguments: IApiArgument[] = []
) => {
  const { retryBackoffFactor = 0.25, retryDelay = 0.5, retryTimeout = 300 } = getRetrySources(
    retryArguments
  );
  const startTime = DateTime.local();
  let pollingInterval = retryDelay;

  const validateTimeoutLimit = async () => {
    const currentTime = DateTime.local();

    return currentTime.diff(startTime, "seconds").seconds >= retryTimeout;
  };

  const delayedQuery = async () => {
    delay(pollingInterval * 1000);
    const queryResult = await watchQuery.refetch();
    const hasPollingErrorCode = validateErrorCode(queryResult.errors);
    const hasReachedMaxTimeout = validateTimeoutLimit();
    pollingInterval = pollingInterval + retryBackoffFactor;

    return !!queryResult.data || hasReachedMaxTimeout || !hasPollingErrorCode;
  };

  await poll(delayedQuery, retryTimeout, pollingInterval);

  return watchQuery.result();
};

const getRetrySources = (apiArguments: IApiArgument[]) => {
  const retryOptions = reduce(
    apiArguments || [],
    (retry, argument) => {
      const argumentName = argument.name as EnumRetryArgumentName;
      const argumentValue = parseFloat(argument.source);

      if (retry[argumentName] && argumentValue) {
        retry[argumentName] = argumentValue;
      }

      return retry;
    },
    {
      retryBackoffFactor: 0.25,
      retryDelay: 0.5,
      retryTimeout: 300,
    }
  );

  return retryOptions;
};

const validateErrorCode = (errors: readonly GraphQLError[] = []) => {
  return errors.some(error => POLLING_ERROR_CODES.includes(error.message as ErrorCodes));
};
