import { useModal } from "modules";

export const useShowVerificationEmailSentModal = () => {
  const openModal = useModal();

  const showVerificationEmailSentModal = () => {
    openModal("VerificationEmailSendModal");
  };

  return showVerificationEmailSentModal;
};
