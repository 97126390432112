import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

interface IVerifyEmailResponse {
  data: {
    verifyEmail: {
      success: boolean;
    };
  };
}

export const verifyEmailMutation = (token: string): Promise<IVerifyEmailResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation verifyEmail($input: VerifyEmailInput!) {
      verifyEmail(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: { token },
    },
    mutation,
  });
};
