import { gql } from "config/graphql";
import { GQLUserResponse, IUser } from "config/types";
import { CLASSIFICATION_FIELDS, POLICY_FIELDS } from "graphql/fragments";
import { getApolloClient } from "store/apollo";

interface IFetchCurrentUserPolicyResponse {
  data: {
    me: IUser;
  };
}

export const fetchCurrentUserPolicyQuery = (): Promise<IFetchCurrentUserPolicyResponse> => {
  const client = getApolloClient();

  const query = gql`
    ${CLASSIFICATION_FIELDS}
    ${POLICY_FIELDS}
    query userPolicy {
      me {
        activeConstellations {
          activeInsuranceMembership {
            id
            activePolicy {
              ...PolicyFields
            }
            latestPayableInvoice {
              id
              dueAt
              status
              amount
              balance
              issuedAt
              metadata
            }
            pendingPolicy {
              id
              amount
              status
              termDuration
              termUnit
              effectiveAt
              pausedAt
              terminatesAt
              product {
                pendingPlan {
                  id
                  name
                  amount
                  billingFrequency
                }
              }
              documents {
                id
                name
                slug
                html {
                  url
                  fileName
                }
                pdf {
                  url
                  fileName
                }
              }
            }
          }
        }
      }
    }
  `;

  return client.query({ query });
};

export const fetchReinstateablePolicies = () => {
  const client = getApolloClient();

  const query = gql`
    query eligibleForReinstatementPolicies($eligibleForReinstatement: Boolean) {
      policies(eligibleForReinstatement: $eligibleForReinstatement) {
        amount
        effectiveAt
        effectiveUntil
        documents {
          html {
            url
          }
          slug
        }
        id
        interestDue
        label
        monthlyPremium
        product: productV2 {
          name
        }
        reinstatementRequest {
          productsOffering {
            amount
            dueToday
            expiresAt
            id
            insuredsAgeAtTerm
            interestDue
            premiums {
              amount
              billingFrequency
            }
            status
            totalMissedPayments
          }
          status
          underwritingRequired
        }
        status
        terminatesAt
        totalMissedPremiums
      }
    }
  `;

  return client.query({
    query,
    variables: {
      eligibleForReinstatement: true,
    },
  });
};

export const policyRidersQuery = (): Promise<GQLUserResponse<"activeConstellations">> => {
  const client = getApolloClient();

  const query = gql`
    query PolicyRiders {
      me {
        activeConstellations {
          activeInsuranceMembership {
            activePolicy {
              policyRiders {
                id
                label
                rider {
                  cta
                  ctaDestination
                  description
                  name
                }
              }
            }
          }
        }
      }
    }
  `;

  return client.query({ fetchPolicy: "cache-first", query });
};

export const policyDocumentsQuery = (): Promise<GQLUserResponse<"activeConstellations">> => {
  const client = getApolloClient();

  const query = gql`
    query PolicyDocuments {
      me {
        activeConstellations {
          activeInsuranceMembership {
            activePolicy {
              documents {
                id
                name
                slug
                html {
                  url
                  fileName
                }
                pdf {
                  url
                  fileName
                }
              }
            }
          }
        }
      }
    }
  `;

  return client.query({ query });
};
