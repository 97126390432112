import { gql } from "config/graphql";

const DYNAMIC_FORM_ITEM_CONTENT = gql`
  fragment DynamicFormItemContentFields on DynamicFormsItemsContent {
    ... on DynamicFormsItemsInputsCheckboxContent {
      acknowledgementGroupKeys
      disabled
      id
      label
      required
      text
      value
    }
    ... on DynamicFormsItemsInputsDateContent {
      disabled
      hint
      id
      label
      max
      min
      required
      value
    }
    ... on DynamicFormsItemsInputsMultiSelectContent {
      disabled
      id
      label
      options {
        key
        label
        value
      }
      required
      value
    }
    ... on DynamicFormsItemsInputsNumericContent {
      currency
      disabled
      hint
      id
      label
      max
      min
      precision
      required
      unit
      value
    }
    ... on DynamicFormsItemsInputsSingleSelectContent {
      disabled
      element
      format
      hint
      id
      label
      options {
        key
        label
        value
      }
      required
      value
    }
    ... on DynamicFormsItemsInputsSliderContent {
      characterMax
      disabled
      id
      increment
      label
      leftTextLabel
      maxErrorMessage
      minErrorMessage
      required
      rightTextLabel
      textEditable
      ticks {
        label
        value
      }
      value
      valueBase
    }
    ... on DynamicFormsItemsInputsTaxIdContent {
      disabled
      encryptOutput
      hint
      id
      label
      required
      value
    }
    ... on DynamicFormsItemsInputsTextContent {
      allowEmptySubmit
      characterMax
      disabled
      element
      format
      hint
      id
      label
      required
      value
    }
    ... on DynamicFormsItemsInputsUnitizedContent {
      disabled
      id
      label
      options {
        label
        max
        min
      }
      required
      value
    }
    ... on DynamicFormsItemsStaticsButtonContent {
      actions {
        command {
          arguments {
            name
            source
            sourceType
            type
          }
          destination
        }
        type
      }
      fixed
      primary
      style
      text
    }
    ... on DynamicFormsItemsStaticsDecorationContent {
      slug
    }
    ... on DynamicFormsItemsStaticsImageContent {
      alignment
      fillColor
      height
      name
      width
    }
    ... on DynamicFormsItemsStaticsListRowContent {
      actions {
        command {
          arguments {
            name
            source
            sourceType
            type
          }
          destination
        }
        type
      }
      leadingColumn {
        actions {
          command {
            arguments {
              name
              source
              sourceType
              type
            }
            destination
          }
          type
        }
        image {
          alignment
          fillColor
          height
          name
          width
        }
        texts {
          color
          html
          text
          typography
        }
      }
      showsDivider
      trailingColumn {
        actions {
          command {
            arguments {
              name
              source
              sourceType
              type
            }
            destination
          }
          type
        }
        image {
          alignment
          fillColor
          height
          name
          width
        }
        texts {
          color
          html
          text
          typography
        }
      }
      verticalPadding
    }
    ... on DynamicFormsItemsStaticsSpacerContent {
      min
      mode
    }
    ... on DynamicFormsItemsStaticsTextContent {
      color
      html
      text
      typography
    }
  }
`;

const DYNAMIC_FORM_ITEM = gql`
  ${DYNAMIC_FORM_ITEM_CONTENT}
  fragment DynamicFormItemFields on DynamicFormsItem {
    content {
      ...DynamicFormItemContentFields
    }
    key
    type
  }
`;

const DYNAMIC_FORM_GROUP_DETAIL = gql`
  fragment DynamicFormGroupDetailFields on DynamicFormsGroupsDetail {
    ... on DynamicFormsGroupsApi {
      key
      mutationBody
      mutationName
      mutationVariables {
        name
        source
        sourceType
        type
      }
    }
    ... on DynamicFormsGroupsComponent {
      itemKeys
      replacementKey
      type
    }
  }
`;

const DYNAMIC_FORM_GROUP = gql`
  ${DYNAMIC_FORM_GROUP_DETAIL}
  fragment DynamicFormGroupFields on DynamicFormsGroup {
    detail {
      ...DynamicFormGroupDetailFields
    }
    type
  }
`;

export const DYNAMIC_FORM = gql`
  ${DYNAMIC_FORM_GROUP}
  ${DYNAMIC_FORM_ITEM}
  fragment DynamicFormFields on DynamicFormsForm {
    description
    flow {
      slug
    }
    groups {
      ...DynamicFormGroupFields
    }
    id
    items {
      ...DynamicFormItemFields
    }
    layout
    leadingNavigationButton {
      actions {
        command {
          arguments {
            name
            source
            sourceType
            type
          }
          destination
        }
        type
      }
      fixed
      primary
      style
      text
    }
    slug
  }
`;
