import { FC } from "react";

import { ConditionKey } from "config";
import { EnumFlowTypes, ValueOrArray } from "config/types";
import CreateAccount from "containers/CreateAccount/CreateAccount";
import CreatePhoneNumber from "containers/CreatePhoneNumber/CreatePhoneNumber";
import HonestyPledge from "containers/HonestyPledge/HonestyPledge";
import HonestyPledgeEligibility from "containers/HonestyPledgeEligibility/HonestyPledgeEligibility";
import Ineligibility from "containers/IneligibleWarningContainer/IneligibleWarningContainer";
import PaymentConfirmation from "containers/PaymentConfirmation/PaymentConfirmation";
import PurchaseReinstatedPolicy from "containers/PurchasePolicy/PurchaseReinstatedPolicy";
import VerifySession from "containers/VerifySession/VerifySession";
import WishesIntro from "containers/Wishes/WishesIntro/WishesIntro";
import WyshBuilderLite from "containers/WyshBuilderLite/WyshBuilderLite";
import DashboardRoute from "router/DashboardRoute/DashboardRoute";
import FlowRoute from "router/FlowRoute/FlowRoute";
import PurchasePolicyRoute from "router/PurchasePolicyRoute/PurchasePolicyRoute";
import QuoteRoute from "router/QuoteRoute/QuoteRoute";

import { filter, isString, mapKeys, mapValues, merge } from "lodash-es";

export type JourneyConfigSection = {
  key: string;
  component: FC;
  route: string;
  conditions?: ValueOrArray<ConditionKey>;
};

const registrationSection: JourneyConfigSection[] = [
  {
    key: "create-account",
    component: CreateAccount,
    route: "/products/insurance/welcome",
    conditions: "auth.authenticated",
  },
  {
    key: "create-phone",
    component: CreatePhoneNumber,
    route: "/create-phone",
    conditions: ["featureFlags.skipPhoneNumber"],
  },
  {
    key: "verify-otp",
    component: VerifySession,
    route: "/verify-phone",
    conditions: ["featureFlags.skipPhoneNumber"],
  },
];

const wyshSection: JourneyConfigSection[] = [
  {
    key: "wysh-intro",
    component: WishesIntro,
    route: "/wysh-builder",
  },
  {
    key: "wysh-builder",
    component: WyshBuilderLite,
    route: "/wysh-builder/list",
  },
];

const sections: Record<string, JourneyConfigSection[]> = {
  registration: registrationSection,
  wyshes: wyshSection,
};

const screens = {
  home: DashboardRoute,
  dashboard: DashboardRoute,
  eligibility: Ineligibility,
  "honesty-pledge": HonestyPledge,
  "honesty-pledge-eligibility": HonestyPledgeEligibility,
  "payment-confirmation": PaymentConfirmation,
  "quote-review-route": QuoteRoute,
  "purchase-policy": PurchasePolicyRoute,
  "purchase-reinstated-policy": PurchaseReinstatedPolicy,
};

const formKeys = mapKeys(filter(EnumFlowTypes, isString));
const flows = mapValues(formKeys, () => FlowRoute);

export const journeyConfig = merge({}, sections, screens, flows);
