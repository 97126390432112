import "./AboutAd.scss";

import { FC, useEffect } from "react";
import { useSelector } from "react-redux";

import { aboutADDisplayedEvent, productOfferEditEvent } from "analytics/productOfferEvents";
import HeroImage from "assets/images/illustrations/AboutADHero/AboutADHero.svg";
import ADFAQ from "components/ADFAQ/ADFAQ";
import ADOfferCard from "components/ADOfferCard/ADOfferCard";
import { DynamicFormItems } from "config/types/dynamicForm.types";
import { Layout, useJourney, useTranslation } from "modules";
import { useAppDispatch } from "store";
import { getWyshADOffering } from "store/selectors";
import { scrollToTop } from "utils/scrollToTop";

import { push } from "connected-react-router";

import Features from "./Features";

interface IProps {
  dynamicFormItems?: DynamicFormItems[];
  onClickEdit?(offeringType: "termLifeOffering" | "adOffering"): void;
  onClickBack?(): void;
}

const AboutAD: FC<IProps> = props => {
  const { dynamicFormItems, onClickEdit } = props;
  const { t } = useTranslation();
  const adOffering = useSelector(getWyshADOffering);
  const { anyJourneysActive } = useJourney();
  const dispatch = useAppDispatch();

  const hasValidADProduct = adOffering && adOffering?.status !== "expired";

  const content = {
    pretitle: t("ad.about.pretitle", "INTRODUCING"),
    title: t("ad.about.title", "Accidental death insurance"),
    subtitle: t(
      "ad.about.subtitle",
      "Unlike term life insurance, that covers most cases of death, accidental death insurance is focused only on accident-related deaths. See below for more details."
    ),
    heroImage: HeroImage,
    twoOfferings: hasValidADProduct,
  };

  const handleEditClick = () => {
    if (onClickEdit) {
      onClickEdit("adOffering");
    } else {
      dispatch(push("/edit-coverage/adOffering"));
    }
    dispatch(productOfferEditEvent("ad"));
  };

  useEffect(() => {
    dispatch(aboutADDisplayedEvent());
    scrollToTop({ behavior: "auto" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Layout
        className="about-ad"
        as="ADProductOfferingLayout"
        content={content}
        backNav={{
          hasBackNav: false,
          replaceExitInJourneyWithBack: anyJourneysActive,
        }}>
        <Features />
        {adOffering && adOffering.status !== "expired" && (
          <ADOfferCard
            mode="purchase"
            productOffering={adOffering}
            onClickEdit={handleEditClick}
            dynamicFormItems={dynamicFormItems}
          />
        )}
      </Layout>
      <div className="result-ad__faq">
        <ADFAQ />
      </div>
    </>
  );
};

export default AboutAD;
