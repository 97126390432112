import "./PaymentConfirmation.scss";

import { FC, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { billingCompletedConfirmEvent, billingCompleteEvent } from "analytics/billingEvents";
import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import List from "components/List/List";
import ListItem from "components/ListItem/ListItem";
import Text from "components/Text/Text";
import VariableText from "components/VariableText/VariableText";
import { ButtonFormItem, DynamicFormItems } from "config";
import { useButtonAction } from "containers/DynamicForm/useButtonAction";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { useAppSelector } from "store";
import { finishJourney } from "store/actions/journey";
import { setModal } from "store/actions/modal";
import { resolveMUWNotification } from "store/actions/notifications";
import formatCommas from "utils/formatCommas";

import { push } from "connected-react-router";
import { DateTime } from "luxon";

import { DownloadPolicyPacketButton } from "./DownloadPolicyPacketButton";

type PolicyDetailItem = {
  label: string;
  value: string;
};

type ProductSpecificContent = {
  header: string;
  policyDetailsItems: PolicyDetailItem[];
  paymentDetailsItems: PolicyDetailItem[];
};

interface IProps {
  dynamicFormItems?: DynamicFormItems[];
}

const PaymentConfirmation: FC<IProps> = props => {
  const { dynamicFormItems } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const policy = useAppSelector(state => state.policy);
  const emailAddress = useAppSelector(state => state.profile.email?.email);
  const firstName = useAppSelector(state => state.profile.firstName);

  const continueButton = dynamicFormItems?.find(
    item => item.key === "purchase-button"
  ) as ButtonFormItem;

  const continueButtonActions = useButtonAction(continueButton?.content?.actions || []);

  const handleSupportClick = () => {
    dispatch(setModal("CustomerSupportModal"));
  };

  const endJourney = () => {
    dispatch(billingCompletedConfirmEvent());
    dispatch(finishJourney("fulfillment"));

    // Execute df3k button item
    if (continueButton) {
      continueButtonActions();
    } else {
      dispatch(push("/"));
    }
  };

  const productSpecificContent = useMemo(() => {
    const policyEffectiveDate = DateTime.fromISO(policy.effectiveAt).toFormat("DDD");
    const policyPlan = policy.activePlan;
    const paymentDetailsItems = [
      {
        label: t(
          "payment_confirmation.payment_details.plan_premium.monthly.label",
          "Monthly premium"
        ),
        value: `$${formatCommas(policyPlan?.amount || 0)}`,
      },
      {
        label: t("payment_confirmation.payment_details.effective_date.label", "Paid on"),
        value: policyEffectiveDate,
      },
    ];

    const productSpecificContentMap: Record<string, ProductSpecificContent> = {
      "wysh-accidental-death": {
        header: t("payment_confirmation.header.ad", "Congrats on your Accidental Death policy!"),
        policyDetailsItems: [
          {
            label: t("payment_confirmation.policy_details.policy_label.title", "Policy number"),
            value: policy.label,
          },
          {
            label: t("payment_confirmation.policy_details.policy_type.title", "Policy type"),
            value: t(
              "payment_confirmation.policy_details.policy_type.value.ad",
              "Accidental Death Insurance"
            ),
          },
          {
            label: t(
              "payment_confirmation.policy_details.coverage_amount.title",
              "Coverage amount"
            ),
            value: t(
              "payment_confirmation.policy_details.coverage_amount.value.ad",
              "Up to $%<coverageAmount>s",
              { coverageAmount: formatCommas(policy.amount) }
            ),
          },
          {
            label: t("payment_confirmation.policy_details.coverage_term.title", "Term length"),
            value: t(
              "payment_confirmation.policy_details.coverage_term.value.ad",
              "Guaranteed until you're %<insuredsAgeAtTerm>s y/o",
              { insuredsAgeAtTerm: policy.insuredsAgeAtTerm }
            ),
          },
        ],
        paymentDetailsItems,
      },

      "wysh-term-life": {
        header: t("payment_confirmation.header.tl", "Congrats on your new policy!"),
        policyDetailsItems: [
          {
            label: t("payment_confirmation.policy_details.policy_label.title", "Policy number"),
            value: policy.label,
          },
          {
            label: t("payment_confirmation.policy_details.policy_type.title", "Policy type"),
            value: t(
              "payment_confirmation.policy_details.policy_type.value.tl",
              "Term Life Insurance"
            ),
          },
          {
            label: t(
              "payment_confirmation.policy_details.coverage_amount.title",
              "Coverage amount"
            ),
            value: t(
              "payment_confirmation.policy_details.coverage_amount.value.tl",
              "$%<coverageAmount>s",
              { coverageAmount: formatCommas(policy.amount) }
            ),
          },
          {
            label: t("payment_confirmation.policy_details.coverage_term.title", "Term Length"),
            value: t(
              "payment_confirmation.policy_details.coverage_term.tl",
              "%<insuredsAgeAtTerm>s years",
              {
                insuredsAgeAtTerm: policy.termDuration,
              }
            ),
          },
        ],
        paymentDetailsItems,
      },
    };

    return productSpecificContentMap[
      policy.productDetail.slug as keyof typeof productSpecificContentMap
    ];
  }, [
    policy.activePlan,
    policy.amount,
    policy.effectiveAt,
    policy.insuredsAgeAtTerm,
    policy.termDuration,
    policy.label,
    policy.productDetail.slug,
    t,
  ]);

  const listItemIterator = ({ label, value }: { label: string; value: string }) => {
    return (
      <ListItem
        className="payment-confirmation-details__list-item"
        disabled
        heightAuto
        key={label}
        text={label}
        rightText={value}
        textTag="b4"
        rightTextTag="l4"
      />
    );
  };

  useEffect(() => {
    // Check for Underwriting Notifications
    dispatch(resolveMUWNotification());
    dispatch(billingCompleteEvent());
  }, [dispatch]);

  return (
    <Layout as="FullWidthLayout" className="payment-confirmation">
      <div className="payment-confirmation-header">
        <Icon
          className="payment-confirmation-header__icon"
          src="Check"
          size="md"
          color="success-dark"
        />
        <Text
          className="payment-confirmation-header__title"
          tag="h3"
          text={productSpecificContent.header}
        />

        <Text
          tag="p3"
          className="payment-confirmation-header__subheader"
          text={t(
            "payment_confirmation.subheader_1",
            "Welcome to the Wysh family, %<name>s! Your policy is now active, providing you with the peace of mind you deserve.",
            { name: firstName }
          )}
        />
        <Text tag="p3" className="payment-confirmation-header__subheader">
          <VariableText
            target={/(?:<strong.*?>|<\/strong>)/g}
            text={t(
              "payment_confirmation.subheader_2",
              "A confirmation email and future policy notices will be sent to <strong>%<emailAddress>s</strong>",
              { emailAddress: emailAddress || "" }
            )}
            replacementElement="strong"
          />
        </Text>
      </div>

      <div className="payment-confirmation-details">
        <div className="payment-confirmation-details_policy-details__wrapper">
          <Text
            className="payment-confirmation-details__header"
            tag="l1"
            text={t("payment_confirmation.details.policy.header", "Your policy")}
          />

          <List className="payment-confirmation-details__list">
            {productSpecificContent.policyDetailsItems.map(listItemIterator)}
          </List>
        </div>
        <div className="payment-confirmation-details_policy-details__wrapper">
          <Text
            className="payment-confirmation-details__header"
            tag="l1"
            text={t("payment_confirmation.details.payment.header", "Payment information")}
          />

          <List className="payment-confirmation-details__list">
            {productSpecificContent.paymentDetailsItems.map(listItemIterator)}
          </List>
        </div>
        <div className="payment-confirmation-details__download_cta">
          <DownloadPolicyPacketButton />
        </div>
      </div>

      <Button
        className="payment-confirmation-policy-details__cta"
        dataTestId="payment-confirmation__cta"
        onClick={endJourney}
        subtype="primary"
        text={t("payment_confirmation.cta", "GO TO MY DASHBOARD")}
      />

      <div className="payment-confirmation-customer-support">
        <Text className="payment-confirmation-customer-support__header" tag="l5">
          <VariableText
            text={t(
              "payment_confirmation.cs.chat.header",
              "Have questions? <button>Chat with us</button>"
            )}
            className="payment-confirmation-customer-support__cta"
            target={/(?:<button.*?>|<\/button>)/g}
            replacementValue={
              <span onClick={handleSupportClick}>
                <Text
                  tag="l5"
                  as="span"
                  text={t("payment_confirmation.cs.chat.cta", "Chat with us")}
                />
              </span>
            }
          />
        </Text>

        <Text
          className="payment-confirmation-customer-support__additional-info"
          tag="b5"
          text={t(
            "payment_confirmation.cs.chat.info",
            "Live chat support is available Monday-Friday during business hours (9am-5pm EST)."
          )}
        />
      </div>
    </Layout>
  );
};

export default PaymentConfirmation;
